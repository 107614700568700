import { useNavigate, Link } from 'react-router-dom';
import { track } from '@vercel/analytics';
import './App.css';

const Root = () => {
  const navigate = useNavigate();

  // TODO: Convert to tailwindcss
  const breedersContainer = {
    paddingTop: '30px',
    paddingBottom: '50px',
    textAlign: 'center' as const,
    color: 'black',
    width: '100%',
    // height: '100vh', // This will cover the full viewport height
    overflow: 'auto', // In case the content exceeds the viewport height
    fontSize: '24px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column' as const,
    minHeight: '100vh',
  };

  const trackContactClick = () => {
    track(`Button Clicked: Footer Contact Link`);
  };

  return (
    <div className="bg-[#f6ffff] font-brandon">
      <div style={breedersContainer}>
        <div className="flex justify-center">
          <img src="/strain-web-logo.jpg" alt="logo" style={{ width: '30%', height: 'auto' }} />
        </div>
        <div className="mb-3 mt-2 font-brandon">
          <div className="text-md">Welcome to strainweb.io</div>
          <div className="flex flex-col justify-center items-center pt-1 text-sm">
            <div className="flex flex-row justify-center pt-1 text-sm underline mb-1">
              <Link to="/changelog">Changelog</Link>
            </div>
            <div className="flex flex-row justify-center pt-1 text-sm underline mb-4">
              <Link to="/tools/dli-calculator">Daily Light Integral (DLI) Calculator</Link>
            </div>
          </div>
          <div className="text-sm">Select a breeder below to view their lineage data</div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-2 justify-between">
          <div className="flex items-center justify-center mr-4">
            <img
              src="/new-alebrije-logo.png"
              alt="logo"
              style={{ width: '40%', maxWidth: '550px', height: 'auto', cursor: 'pointer' }}
              onClick={() => navigate('/alebrije')}
            />
          </div>
          <div className="flex items-center justify-center">
            <img
              src="/dark-owl-logo.png"
              alt="logo"
              style={{ width: '45%', maxWidth: '550px', height: 'auto', cursor: 'pointer' }}
              onClick={() => navigate('/dark-owl')}
            />
          </div>
          <div className="flex items-center justify-center">
            <img
              src="/frost-fire-logo.png"
              alt="logo"
              style={{ width: '45%', maxWidth: '550px', height: 'auto', cursor: 'pointer' }}
              onClick={() => navigate('/frostfire')}
            />
          </div>
          <div className="flex items-center justify-center">
            <img
              src="/green-guy.png"
              alt="logo"
              style={{ width: '40%', maxWidth: '550px', height: 'auto', cursor: 'pointer' }}
              onClick={() => navigate('/green-guy')}
            />
          </div>
          <div className="flex items-center justify-center ml-2">
            <img
              src="/humminbird-logo.png"
              alt="logo"
              style={{ width: '50%', height: 'auto', cursor: 'pointer' }}
              onClick={() => navigate('/humminbird-hills')}
            />
          </div>
          <div className="flex items-center justify-center mt-1 mb-1">
            <img
              src="/mephisto-logo.png"
              alt="logo"
              style={{ width: '45%', height: 'auto', cursor: 'pointer' }}
              onClick={() => navigate('/mephisto')}
            />
          </div>
          <div className="flex items-center justify-center">
            <img
              src="/night-owl-logo.png"
              alt="logo"
              style={{ width: '45%', height: 'auto', cursor: 'pointer' }}
              onClick={() => navigate('/night-owl')}
            />
          </div>
          <div className="flex items-center justify-center">
            <img
              src="/photon-pharms-logo.png"
              alt="logo"
              style={{ width: '55%', maxWidth: '550px', height: 'auto', cursor: 'pointer' }}
              onClick={() => navigate('/photon-pharms')}
            />
          </div>
          <div className="flex items-center justify-center">
            <img
              src="/ronin-garden-logo.png"
              alt="logo"
              style={{ width: '55%', maxWidth: '550px', height: 'auto', cursor: 'pointer' }}
              onClick={() => navigate('/ronin-garden')}
            />
          </div>
        </div>
        {/* <div style={{ marginTop: '16px', marginBottom: '14px', fontSize: '12px' }}> */}
        <div className="mt-6 mb-4 text-sm font-brandon">
          Made with <span style={{ marginRight: '2px' }}>❤️</span> by TOB
          <div>
            <a
              className="underline"
              target="_blank"
              rel="noreferrer"
              href="https://linktr.ee/tobgrows"
              onClick={trackContactClick}
            >
              Contact
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Root;
