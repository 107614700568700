import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as d3 from 'd3';
import Select from 'react-select';
import { track } from '@vercel/analytics';
import { scrollIntoView } from 'seamless-scroll-polyfill';
import { type ConnectionCounts, type ProcessedStrainNodeWithCoords, processGenealogyData } from './processData';
import { Person } from './AlebrijeData';
import { type Strain } from './Strain';
import { type Link } from './types';
import { calculateBubbleRadius } from './utils/calculators';

import './App.css';

// Consistent across all breeders
const NODE_LINK_COLOR = '#c2c5aa';
const SELECTED_NODE_BORDER_COLOR = '#405a5e';

export interface WebConfig {
  breederName: string;
  parentStrainColor: string;
  childStrainColor: string;
  featuredColor: string;
  nodeColorScaleRgb: string;
  viewBoxHeight: number;
  viewBoxWidth: number;
  // Dropdown/url param/etc
  defaultStrainUrlParam: string;
  defaultStrainName: string;
  defaultDropdownValue: DropdownValue;
  // Overrides
  nodeCollisionRadiusOverride?: number;
}

interface GenealogyTreeProps {
  people: Person[];
  webConfig: WebConfig;
}

interface Node extends d3.SimulationNodeDatum {
  name: string;
}

// This is/was some weirdo shit that I had to deal with
// type ExtendedNodeDatum = d3.SimulationNodeDatum & ProcessedNodeData;

type DropdownValue = { label: string; value: string; link: string | null };

const formatDropdownValueOptionFromNode = (node: ProcessedStrainNodeWithCoords): DropdownValue => {
  return { label: node.name, value: node.name, link: node.link };
};

// what is 'Person' here actually? I believe it actually is a person type
// Its returning a  'DropdownValue' (this is something that should be defined)
// The id here will eventually be replaced with key (each strain has a key like 'sour-stomper')
const formatDropdownValueOptionFromStrain = (strain: Strain) => {
  return { id: strain.id, label: strain.name, value: strain.name, link: strain.link };
};

const sleep = (delay: number) => {
  return new Promise(res => setTimeout(res, delay));
};

const BreederStrainWeb: React.FC<GenealogyTreeProps> = ({ people, webConfig }) => {
  const {
    breederName,
    parentStrainColor,
    childStrainColor,
    featuredColor,
    nodeColorScaleRgb,
    viewBoxHeight,
    viewBoxWidth,
    defaultStrainUrlParam,
    defaultDropdownValue,
    defaultStrainName,
    nodeCollisionRadiusOverride,
  } = webConfig;

  const navigate = useNavigate();
  const svgRef = useRef<SVGSVGElement>(null);
  const {
    nodes: nodesData,
    links: linksData,
    connectionCounts,
  }: { nodes: any; links: Link[]; connectionCounts: ConnectionCounts } = processGenealogyData(people);

  // STATE
  const [dropdownValue, setDropdownValue] = useState<DropdownValue | null>(null);
  const [selectedStrainName, setSelectedStrainName] = useState('');
  const [shouldRenderNodes, setShouldRenderNodes] = useState(true);
  const [showMenu, setShowMenu] = useState(true);
  // *********************

  // ZOOM HANDLING
  const [zoomLevel, setZoomLevel] = useState(1.05);

  const handleZoomIn = () => {
    setZoomLevel(zoomLevel => zoomLevel * 1.25); // Increase zoom by 25%
  };

  const handleZoomOut = () => {
    setZoomLevel(zoomLevel => zoomLevel * 0.75); // Decrease zoom by 25%
  };
  // ********************************

  // URL STRAIN PARAMS HANDLING
  const queryParams = new URLSearchParams(window.location.search);
  const strainParam = queryParams.get('s');

  const updateUrlStrainParam = (newUrlStrainParam: any) => {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set('s', newUrlStrainParam);
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.replaceState({ path: newUrl }, '', newUrl);
  };

  const removeUrlStrainParam = () => {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.delete('s'); // Remove the "s" parameter

    // Check if the modified query string is empty
    const queryString = queryParams.toString();
    const newUrl = queryString ? `${window.location.pathname}?${queryString}` : window.location.pathname;

    window.history.replaceState({ path: newUrl }, '', newUrl);
  };
  // ***********************

  // ****DROPDOWN
  const handleSelectionChange = (event: any) => {
    setSelectedStrainName(event.label);
    setDropdownValue(event);
    // Update the url parameter anytime someone selects a new strain so that we can share it
    // We'll update this to event.key when we're using strain.key instead of .id
    updateUrlStrainParam(event.id);
  };

  const dropdownOptions = people
    .map(person => {
      return formatDropdownValueOptionFromStrain(person);
    })
    .sort((a, b) => a.label.localeCompare(b.label));

  const formatOptionLabel = ({ _value, label, _shortDescr }: any) => <span style={{ fontSize: '14px' }}>{label}</span>;
  // ************

  useEffect(() => {
    if (!svgRef.current) return;

    const svg = d3.select(svgRef.current);

    if (shouldRenderNodes) {
      svg.selectAll('*').remove();

      const maxConnections = Math.max(...Object.values(connectionCounts));
      const interpolatedRgb = d3.interpolateRgb('white', nodeColorScaleRgb);
      const colorScale = d3.scaleSequential(interpolatedRgb).domain([0, maxConnections]);

      // Setup the force simulation
      const simulation = d3
        .forceSimulation(nodesData)
        .force(
          'link',
          d3
            // The links are set up with a `source: name` and `target: name`
            // So we use .id to specify "this is the identifier to use to connect the links"
            // and in this situation, the identifier is the raw name
            .forceLink(linksData) // These links are { source: parentName, target: strain/childName }
            .id(d => (d as Node).name)
            .distance(400)
        )
        .force('charge', d3.forceManyBody().strength(-500))
        .force('center', d3.forceCenter(viewBoxWidth / 2, viewBoxHeight / 2))
        .force(
          'collide',
          d3.forceCollide().radius(function (d) {
            return nodeCollisionRadiusOverride || 140;
          })
        );

      // Add lines for links
      const nodeLinks: d3.Selection<SVGLineElement, Link, SVGGElement, unknown> = svg
        .append('g')
        .selectAll<SVGLineElement, Link>('line')
        .data<Link>(linksData)
        .join('line')
        .style('stroke', NODE_LINK_COLOR)
        .style('stroke-width', 3);

      // Add circles for nodes with color based on number of connections
      const node = svg
        .append('g')
        .selectAll('circle')
        .data(nodesData)
        .join('circle')
        // Make the featured bubbles a bit larger
        .attr('r', (d: any) =>
          d.featured
            ? calculateBubbleRadius(connectionCounts[d.name]) + 15
            : calculateBubbleRadius(connectionCounts[d.name])
        )
        .attr('id', (d: any) => `node-${d.name.replace(/[^a-zA-Z0-9]/g, '')}`)
        .style('fill', (d: any) => (d.featured ? featuredColor : colorScale(connectionCounts[d.name] || 0)))
        .style('stroke', (d: any) => (d.featured ? featuredColor : ''))
        .style('stroke-width', (d: any) => (d.featured ? '6px' : ''))
        .style('cursor', 'pointer');

      const labelGroup = svg
        .append('g')
        .selectAll('g')
        .data(nodesData)
        .join('g')
        .attr('text-anchor', 'middle')
        .style('font-size', '24px')
        .style('font-weight', '600')
        .style('cursor', 'pointer');

      labelGroup
        .append('text')
        .attr('y', (d: any) => (d.name.includes(' x ') ? '-1.2em' : '0'))
        .selectAll('tspan')
        .data((d: any) => {
          // Split the name into parts based on ' x '
          return d.name.split(' x ');
        })
        .enter()
        .append('tspan')
        .text((part: any, index: any, parts: any) => {
          // Add ' x ' back except for the last part
          return index < parts.length - 1 ? part + ' x' : part;
        })
        .attr('x', 0) // Set the x position for each tspan
        .attr('dy', (d, i, nodes) => {
          // Access the original data bound to the parent text element
          const originalData: any = d3.select(nodes[i].parentNode as any).datum();
          // Determine the number of parts by splitting the 'name' property
          const partsLength = originalData.name.split(' x ').length;
          // Apply '1.2em' for labels with ' x ', otherwise '0.4em'
          return partsLength === 2 ? '1.1em' : '0.4em';
        });

      // Hover effect
      node.on('click', function (event, d) {
        // 'd' is unknown because of d3Js not being adapated to Typescript
        // So we have to clean that up here, given that we know what the type actually looks ilke
        const clickedNodeData = d as ProcessedStrainNodeWithCoords;

        d3.select(this)
          .style('stroke', SELECTED_NODE_BORDER_COLOR) // Set the stroke color
          .style('stroke-width', '3px'); // Set the stroke width;

        // Child colors
        nodeLinks
          .filter(link => {
            return link.source === clickedNodeData || link.target === clickedNodeData;
          })
          .style('stroke-width', 7)
          .style('stroke', childStrainColor);

        // Add parent colors
        nodeLinks
          .filter(link => link.target === clickedNodeData)
          .style('stroke-width', 10)
          .style('stroke', parentStrainColor);

        const dropdownValue = formatDropdownValueOptionFromNode(clickedNodeData);

        setDropdownValue(dropdownValue);
        setSelectedStrainName(clickedNodeData.name);

        // MAKE SURE TO UPDATE THE URL PARAMS WHEN A BUBBLE IS SELECTED
        // Find strain ID via people.name = d.name
        // Grab the id off of that
        // NOTE: Another pain point re: not every strain having a 'key'
        // We shouldnt need to do all of this
        const strainKeyForParams = clickedNodeData.key;

        if (strainKeyForParams) {
          updateUrlStrainParam(strainKeyForParams);
        } else {
          removeUrlStrainParam();
        }
      });

      // Ensure when labels are clicked, it acts as if the node was clicked
      labelGroup.on('click', function (event, d) {
        const clickedNodeData = d as ProcessedStrainNodeWithCoords;

        const dropdownValue = formatDropdownValueOptionFromNode(clickedNodeData);
        setDropdownValue(dropdownValue);
        setSelectedStrainName(clickedNodeData.name);

        // MAKE SURE TO UPDATE THE URL PARAMS WHEN A LABEL IS SELECTED
        // Find strain ID via people.name = d.name
        // Grab the id off of that
        // This will be converted to findStrainKeyWithName most likely
        const strainKeyForParams = clickedNodeData.key;

        if (strainKeyForParams) {
          updateUrlStrainParam(strainKeyForParams);
        } else {
          removeUrlStrainParam();
        }
      });

      // Update positions on every tick
      simulation.on('tick', () => {
        nodeLinks
          .attr('x1', (d: any) => d.source.x)
          .attr('y1', (d: any) => d.source.y)
          .attr('x2', (d: any) => d.target.x)
          .attr('y2', (d: any) => d.target.y);

        node.attr('cx', (d: any) => d.x).attr('cy', (d: any) => d.y);

        labelGroup.attr('transform', (d: any) => `translate(${d.x}, ${d.y})`);
      });

      setShouldRenderNodes(false);
    }
  }, [
    nodesData,
    linksData,
    connectionCounts,
    selectedStrainName,
    shouldRenderNodes,
    people,
    childStrainColor,
    parentStrainColor,
    featuredColor,
    nodeColorScaleRgb,
    viewBoxHeight,
    viewBoxWidth,
    nodeCollisionRadiusOverride,
  ]);

  useEffect(() => {
    // Highlight a specific strain based on name
    if (selectedStrainName && dropdownValue && svgRef.current) {
      const svg = d3.select(svgRef.current);

      // Reset all nodes and links to default styles
      svg.selectAll('circle').style('stroke-width', 0);

      svg
        .selectAll('line')
        .style('stroke-width', 3) // make this size a constant
        .style('stroke', NODE_LINK_COLOR); // make this color a constat

      // Improve this man
      const formattedNodeId = selectedStrainName.replace(/[^a-zA-Z0-9]/g, '');

      // Find the D3 node by ID
      const d3Node = svg.select(`circle#node-${formattedNodeId}`) as any;

      if (!d3Node.empty()) {
        // Apply styles to the selected node
        d3Node
          .style('stroke', SELECTED_NODE_BORDER_COLOR) // Set the stroke color
          .style('stroke-width', '3px'); // Set the stroke width;;

        // Apply styles to links related to the selected node
        svg
          .selectAll<SVGLineElement, Link>('line')
          .filter((l: Link) => l.source.name === selectedStrainName || l.target.name === selectedStrainName)
          .style('stroke-width', 7)
          .style('stroke', childStrainColor)
          .raise();

        svg
          .selectAll<SVGLineElement, Link>('line')
          .filter((l: Link) => l.target.name === selectedStrainName)
          .style('stroke-width', 10)
          .style('stroke', parentStrainColor)
          .raise();

        const nodeElement = d3.select(svgRef.current).select(`circle#node-${formattedNodeId}`).node() as Element;

        // We have to use the polyfill scrollIntoView here because Safari/Mobile browsers dont work
        // properly without it. There are issues with scrollIntoViewOptions
        if (nodeElement) {
          scrollIntoView(nodeElement, { behavior: 'smooth', block: 'center', inline: 'center' });
        }
      }
    }
  }, [linksData, selectedStrainName, dropdownValue, childStrainColor, parentStrainColor]);

  useEffect(() => {
    const zoomToDefault = async () => {
      if (!shouldRenderNodes && svgRef.current && !!!selectedStrainName) {
        // If theres a strain param being passed in
        // We should select that one on initial render
        if (strainParam) {
          // Make sure it exists
          // This .id ties to the 'id' field that we want to rename on the Person/Strain
          // type, something like 'key' or something along those lines? Just not .id
          // id was a bad choice because id is used in a bunch of places
          const selectedStrainFromParams = people.find(p => p.id === strainParam);

          // IF it does
          // Grab it, then find the strain (person) in the list
          // Then set the selected node name to the name
          // Then set the dropdown value based on the info that we need
          if (selectedStrainFromParams) {
            await sleep(1000);
            setSelectedStrainName(selectedStrainFromParams.name);
            setDropdownValue({
              label: selectedStrainFromParams.name,
              value: selectedStrainFromParams.name,
              link: selectedStrainFromParams.link,
            });
            track(`Strain Param Used: ${breederName} - ${selectedStrainFromParams.name}`);
          } else {
            await sleep(1000);
            setSelectedStrainName(defaultStrainName);
            setDropdownValue(defaultDropdownValue);
            updateUrlStrainParam(defaultStrainUrlParam);
          }
        } else {
          await sleep(1000);
          setSelectedStrainName(defaultStrainName);
          setDropdownValue(defaultDropdownValue);
          updateUrlStrainParam(defaultStrainUrlParam);
        }
      }
    };

    zoomToDefault();
  }, [
    selectedStrainName,
    shouldRenderNodes,
    people,
    strainParam,
    breederName,
    defaultDropdownValue,
    defaultStrainName,
    defaultStrainUrlParam,
  ]);

  return (
    <>
      <div
        style={{
          marginLeft: -20,
          width: viewBoxWidth,
        }}
      >
        {!showMenu && (
          <div className="control-panel">
            <div style={{ minWidth: '128px' }} className="key-legend flex flex-col">
              <div className="border-b-[1px] border-black mb-2 pb-1 text-md">
                <span
                  className="underline cursor-pointer flex flex-row justify-between"
                  onClick={() => {
                    navigate('/');
                    track(`Button Clicked: Home - ${breederName}`);
                  }}
                >
                  Home
                  <svg
                    className="w-4 h-4 text-gray-800 dark:text-black"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fillRule="evenodd"
                      d="M11.3 3.3a1 1 0 0 1 1.4 0l6 6 2 2a1 1 0 0 1-1.4 1.4l-.3-.3V19a2 2 0 0 1-2 2h-3a1 1 0 0 1-1-1v-3h-2v3c0 .6-.4 1-1 1H7a2 2 0 0 1-2-2v-6.6l-.3.3a1 1 0 0 1-1.4-1.4l2-2 6-6Z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
              </div>
              <span
                className="underline cursor-pointer flex flex-row justify-between"
                onClick={() => {
                  track(`Button Clicked: Show Menu - ${breederName}`);
                  setShowMenu(true);
                }}
              >
                Show Menu
                <span className="flex items-center">
                  <svg
                    className="w-4 h-4 text-gray-800 dark:text-black"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M18.4 10.3A2 2 0 0 0 17 7H7a2 2 0 0 0-1.5 3.3l4.9 5.9a2 2 0 0 0 3 0l5-6Z"
                    />
                  </svg>
                </span>
              </span>
              {dropdownValue && !!dropdownValue.link && (
                <div className="border-t-[1px] pt-2 mt-2 border-black">
                  <a className="underline" target="_blank" rel="noreferrer" href={dropdownValue.link}>
                    <div
                      className="flex flex-row justify-between"
                      onClick={() => {
                        track(`Button Clicked: Strain Details - ${breederName} - ${selectedStrainName}`);
                      }}
                    >
                      <span>Strain Details</span>
                      <svg
                        className="w-5 h-5 text-gray-800 pl-1 dark:text-black"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeWidth="2"
                          d="M18 14v4.8a1.2 1.2 0 0 1-1.2 1.2H5.2A1.2 1.2 0 0 1 4 18.8V7.2A1.2 1.2 0 0 1 5.2 6h4.6m4.4-2H20v5.8m-7.9 2L20 4.2"
                        />
                      </svg>
                    </div>
                  </a>
                </div>
              )}
            </div>
          </div>
        )}

        {showMenu && (
          <div className="control-panel">
            <div className="key-legend">
              <div className="border-b-[1px] border-black mb-2 pb-1 text-md">
                <span
                  className="underline cursor-pointer flex flex-row justify-between"
                  onClick={() => {
                    navigate('/');
                    track(`Button Clicked: Home - ${breederName}`);
                  }}
                >
                  Home
                  <div className="flex items-center">
                    <svg
                      className="w-4 h-4 text-gray-800 dark:text-black"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fillRule="evenodd"
                        d="M11.3 3.3a1 1 0 0 1 1.4 0l6 6 2 2a1 1 0 0 1-1.4 1.4l-.3-.3V19a2 2 0 0 1-2 2h-3a1 1 0 0 1-1-1v-3h-2v3c0 .6-.4 1-1 1H7a2 2 0 0 1-2-2v-6.6l-.3.3a1 1 0 0 1-1.4-1.4l2-2 6-6Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                </span>
              </div>
              <span style={{ fontSize: '14px', paddingBottom: '8px' }} className="underline">
                Connection Legend
              </span>
              <div style={{ color: parentStrainColor, fontSize: '18px', paddingTop: '4px' }}>Parent Strain</div>{' '}
              <div style={{ color: childStrainColor, fontSize: '18px', paddingTop: '4px' }}>Child Strain</div>
              <div style={{ paddingTop: '8px' }}>
                <Select
                  className="react-select-container"
                  classNamePrefix="react-select"
                  value={dropdownValue}
                  onChange={handleSelectionChange}
                  options={dropdownOptions as any}
                  placeholder={'Select a strain'}
                  formatOptionLabel={formatOptionLabel}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      fontSize: '14px',
                      borderColor: 'black',
                    }),
                    valueContainer: (baseStyles, state) => ({
                      ...baseStyles,
                      maxWidth: '150px',
                    }),
                    menuList: (baseStyles, state) => ({
                      ...baseStyles,
                      maxHeight: '225px',
                    }),
                  }}
                />
              </div>
              <div className="strain-select-info">
                Selecting a strain or clicking a strain node will provide a link for detailed info (if available)
              </div>
              <div className="flex flex-col justify-center text-center items-center border-y-[1px] pb-2 mt-1 border-black">
                <div style={{ fontSize: '18px', width: '100%', fontWeight: 600, paddingTop: '2px' }}>Zoom</div>
                <div className="grid grid-cols-2 gap-4" style={{ height: '42px' }}>
                  <button
                    className="border border-black bg-[#efefef] rounded-lg"
                    onClick={handleZoomIn}
                    style={{ padding: '5px 20px' }}
                  >
                    +
                  </button>
                  <button
                    className="border border-black bg-[#efefef] rounded-lg"
                    onClick={handleZoomOut}
                    style={{ padding: '5px 18px' }}
                  >
                    -
                  </button>
                </div>
              </div>
              <div
                className="flex underline items-center justify-center cursor-pointer mt-2"
                onClick={() => {
                  setShowMenu(false);
                  track(`Button Clicked: Hide Menu - ${breederName}`);
                }}
              >
                <span>Hide Menu</span>

                <svg
                  className="w-5 h-5 text-gray-800 dark:text-black pl-1"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.6 13.7A2 2 0 0 0 7 17h10a2 2 0 0 0 1.5-3.3l-4.9-5.9a2 2 0 0 0-3 0l-5 6Z"
                  />
                </svg>
              </div>
              {dropdownValue && !!dropdownValue.link && (
                <div className="flex items-center align-center justify-center border-t-[1px] pt-2 mt-2 border-black">
                  <a className="underline" target="_blank" rel="noreferrer" href={dropdownValue.link}>
                    <div
                      className="flex flex-row justify-between"
                      onClick={() => {
                        track(`Button Clicked: Strain Details - ${breederName} - ${selectedStrainName}`);
                      }}
                    >
                      <span>Strain Details</span>
                      <svg
                        className="w-5 h-5 text-gray-800 pl-1 dark:text-black"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeWidth="2"
                          d="M18 14v4.8a1.2 1.2 0 0 1-1.2 1.2H5.2A1.2 1.2 0 0 1 4 18.8V7.2A1.2 1.2 0 0 1 5.2 6h4.6m4.4-2H20v5.8m-7.9 2L20 4.2"
                        />
                      </svg>
                    </div>
                  </a>
                </div>
              )}
            </div>
          </div>
        )}

        <svg
          ref={svgRef}
          width={viewBoxWidth}
          height={viewBoxHeight}
          viewBox={`0 0 ${viewBoxHeight * 2} ${viewBoxWidth * 2} `}
          style={{ transform: `scale(${zoomLevel})`, transformOrigin: 'top left' }}
        />
      </div>
    </>
  );
};

export default BreederStrainWeb;
