import { peopleData } from './MephistoData';
import BreederStrainWeb from './BreederStrainWeb';
import { type WebConfig } from './BreederStrainWeb';

const WEB_CONFIG: WebConfig = {
  breederName: 'Mephisto',
  parentStrainColor: '#6D152B',
  childStrainColor: '#61714D',
  featuredColor: '#8198ca', // OSMH PINK COLOR
  nodeColorScaleRgb: 'rgb(39, 145, 72)',
  viewBoxHeight: 4850,
  viewBoxWidth: 4900,
  // Dropdown/url param/etc
  defaultStrainUrlParam: 'old-school-mango-haze',
  defaultStrainName: 'Old School Mango Haze',
  defaultDropdownValue: {
    label: 'Old School Mango Haze',
    value: 'Old School Mango Haze',
    link: 'https://www.mephistogenetics.com/products/old-school-mango-haze',
  },
};

const Mephisto = () => (
  <div className="bg-[#a68a64] w-full h-screen overflow-auto font-brandon">
    <BreederStrainWeb people={peopleData} webConfig={WEB_CONFIG} />
  </div>
);

export default Mephisto;
