export interface ChangelogEntry {
  // collection?: 'Medicinal' | 'Reserva' | 'Limited' | 'Illuminato' | 'Original' | 'Artisanal';
  // id: string;
  // link: string | null;
  // name: string;
  // parents: string[];
  date: string; // This should bet a date/timestamp
  details: string[];
  featured?: boolean | null;
}

export const ChangelogData: ChangelogEntry[] = [
  {
    date: 'August 23, 2024',
    details: ['Alebrije: Added new Tangy Tibuguana crosses'],
    featured: false,
  },
  {
    date: 'August 17, 2024',
    details: ['Mephisto: Added Blue Auto CBD crosses and MephistOreoz crosses'],
    featured: false,
  },
  {
    date: 'August 17, 2024',
    details: ['FrostFire: Added new Cherry Bangarang tester crosses'],
    featured: false,
  },
];
