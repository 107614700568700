import { peopleData } from './GreenGuyData';
import BreederStrainWeb from './BreederStrainWeb';
import { type WebConfig } from './BreederStrainWeb';

const WEB_CONFIG: WebConfig = {
  breederName: 'Green Guy',
  parentStrainColor: '#626cdd',
  childStrainColor: '#5edeb9',
  featuredColor: '#f5c014',
  nodeColorScaleRgb: 'rgb(193, 98, 199)',
  viewBoxHeight: 3850,
  viewBoxWidth: 4050,
  // Dropdown/url param/etc
  defaultStrainUrlParam: 'asgardian-wedding',
  defaultStrainName: 'Asgardian Wedding',
  defaultDropdownValue: {
    label: 'Asgardian Wedding',
    value: 'Asgardian Wedding',
    link: 'https://greenguygenetics.bigcartel.com/product/asgardian-wedding-f4',
  },
};

const GreenGuy = () => (
  <div className="bg-[#a68a64] w-full h-screen overflow-auto font-brandon">
    <BreederStrainWeb people={peopleData} webConfig={WEB_CONFIG} />
  </div>
);

export default GreenGuy;
