import { type Strain } from './Strain';

// GENERAL NOTES
// We end up using 'name' value in a lot of instances because not every 'name' is mapped to an entry in
// the Data set. Ideally, we'd have a 'key' for each and every strain (We will eventually) so it'd be best to
// figure things out once we have a 'key' so that we can use that instead of the name values

// A ProcessedStrain might not have an `id` because there are strains
// That have parents, but those parents dont always have an id because they dont
// have their own entry into the data. Ex an outer edge parent who doesnt have parents listed
// wouldnt have an id, because they arent entered into the table by itself
type ProcessedStrain = {
  id?: string;
  name: string;
  parents: string[];
  link: string | null;
  connectionCount?: number;
  featured?: boolean | null;
};

type ProcessedStrainNode = ProcessedStrain & {
  key?: string;
};

export type ProcessedStrainNodeWithCoords = ProcessedStrainNode & {
  index: number;
  vx: number;
  vy: number;
  x: number;
  y: number;
};

type Link = { source: string; target: string };

// type ProcessedNode = { id: string; name: string; parents: []; connectionCount: number };

// Object built up of strain name keys + number of connections as the value
export type ConnectionCounts = Record<string, number>;

export const processGenealogyData = (strains: Strain[]): any => {
  const strainNamesSet = new Set<string>();
  const processedStrainMap = new Map<string, ProcessedStrain>();

  // Add all primary nodes
  strains.forEach(strain => {
    strainNamesSet.add(strain.name);
    processedStrainMap.set(strain.name, strain);
  });

  // Include parents as nodes if they are not already present
  strains.forEach(strain => {
    strain.parents.forEach(parentName => {
      // Add all of the parents from each child to the complete name set
      strainNamesSet.add(parentName);

      // If we havent come across this parent yet, add it with default attributes
      // to the complete list of parents
      if (!processedStrainMap.has(parentName)) {
        processedStrainMap.set(parentName, { name: parentName, parents: [], link: null });
      }
    });
  });

  // // Could this just be strainsMap somehow?
  // GOAL: get rid of the need to have id: name here
  // We want to be using .name wherever we are using node.id
  // It makes no sense to refer to these things differently
  // Were comparing id == name in places, when in reality we should just rename
  // id to name, and then that part can be figured out easily
  // const nodes: ProcessedNodeData[] = Array.from(strainNamesSet).map(name => {
  //   return {
  //     // Ensure that we have an empty parent list at a minimum. Remember, the outermost parents do not have any parents listed!
  //     // The parents will be overridden by the 'real' values as we dump the info from processedStrainMap.get
  //     parents: [],
  //     link: null,

  //     ...processedStrainMap.get(name),
  //     id: name,
  //     // Why do we have to explicity set this here again?
  //     // Below for connectionCounts[node.name]???
  //     // Why is it doing that?
  //     name: name,
  //   };
  // });

  const nodes: ProcessedStrainNode[] = Array.from(processedStrainMap).map(([strainName, strainDetails]) => {
    return {
      // id: strainName,
      key: strainDetails.id,
      name: strainName,
      parents: strainDetails.parents,
      connectionCount: strainDetails.connectionCount,
      link: strainDetails.link,
      featured: strainDetails.featured,
    };
  });

  // So, what we end up doing is returning links to each of the main components
  // the .forceLink(links) references expect to be dealing with d3.SimulationLinkDatum
  // Which have a `source` and a `target` property on them. That property can be a `Node` or `string` or `number`
  // So because of what that stuff is expecting, it allow us to put the parentName or person.name on it because
  // obviously those are strings.

  // Loop through every single person/strain in the list
  // Look at each of their parents
  // Create a 'link' object from the parent to the child
  // The link looks like source: parentName, and target: childName
  // Thats the structure
  const links: Link[] = [];
  strains.forEach(person => {
    person.parents.forEach(parentName => {
      const parent = processedStrainMap.get(parentName);
      if (parent) {
        links.push({ source: parentName, target: person.name });
      }
    });
  });

  // Originally this was being calculated looking at `link.source.id` and
  // `link.target.id` but AFAICT, those IDs werent always working? I'm not sure what was up
  // Perhaps the IDs were taking time to generate? IDK man

  // In all of this, link.source and link.target both end up being the 'name' value of a given strain
  // as we loop over them, we add to each of the connection counts for each time that something shows up
  // remember, source and target are just strain names
  // Were building up an object where we have a count of all of the times a strains name has been found

  const connectionCounts: ConnectionCounts = {};
  links.forEach((link: any) => {
    connectionCounts[link.source] = (connectionCounts[link.source] || 0) + 1;
    connectionCounts[link.target] = (connectionCounts[link.target] || 0) + 1;
  });

  nodes.forEach(node => {
    // The name WILL always be here, but for some reason its barking about undefined nonsense
    if (node.name) {
      // Add the tallied connectionCount for each of the strain names to the node objects that we created earlier
      node.connectionCount = connectionCounts[node.name] || 0;
    }
  });

  return { nodes, links, connectionCounts };
};
