import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Analytics } from '@vercel/analytics/react';
import './index.css';
import Root from './Root';
// Tools
import { Changelog } from './pages/Changelog';
import DliCalculator from './pages/tools/DliCalculator';
// Breeders
import Alebrije from './Alebrije';
import DarkOwl from './DarkOwl';
import FrostFire from './FrostFire';
import GreenGuy from './GreenGuy';
import HumminbirdHills from './HumminbirdHills';
import Mephisto from './Mephisto';
import NightOwl from './NightOwl';
import Photon from './Photon';
import RoninGarden from './RoninGarden';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
  },
  // Tools
  {
    path: '/changelog',
    element: <Changelog />,
  },
  {
    path: '/tools/dli-calculator',
    element: <DliCalculator />,
  },
  // Breeders
  {
    path: '/alebrije',
    element: <Alebrije />,
  },
  {
    path: '/dark-owl',
    element: <DarkOwl />,
  },
  {
    path: '/frostfire',
    element: <FrostFire />,
  },
  {
    path: '/green-guy',
    element: <GreenGuy />,
  },
  {
    path: '/humminbird-hills',
    element: <HumminbirdHills />,
  },
  {
    path: '/mephisto',
    element: <Mephisto />,
  },
  {
    path: '/night-owl',
    element: <NightOwl />,
  },
  {
    path: '/photon-pharms',
    element: <Photon />,
  },
  {
    path: '/ronin-garden',
    element: <RoninGarden />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <Analytics />
    <RouterProvider router={router} />
  </React.StrictMode>
);
