export interface Person {
  collection?: 'Medicinal' | 'Reserva' | 'Limited' | 'Illuminato' | 'Original' | 'Artisanal';
  id: string;
  link: string | null;
  name: string;
  parents: string[];
  featured?: boolean | null;
}

export const peopleData: Person[] = [
  {
    id: 'auto-erotic-assimilation',
    name: 'Auto Erotic Assimilation',
    parents: ['Walter White', 'Cognition Amplifier'],
    link: null,
  },
  // this is a photo
  // {
  //   id: 'brown-brown',
  //   name: 'Brown Brown',
  //   parents: ['Yeyo', 'Malawi'],
  //   link: null,
  // },
  // ****
  {
    id: 'bum-wine',
    name: 'Bum Wine',
    parents: ['Hubbabubbasmelloscope', 'Tyrone Special'],
    link: null,
  },
  // ****
  {
    id: 'chachi',
    name: 'Chachi',
    parents: ['White Widow XXL', 'Cheech Wizard'],
    link: null,
  },
  // ****
  {
    id: 'chikara',
    name: 'Chikara',
    parents: ['OG Kush Auto', 'Blue Power'],
    link: null,
  },
  // ****
  {
    id: 'cognition-amplifier',
    name: 'Cognition Amplifier',
    parents: ['White Widow XXL', 'Zapotec'],
    link: null,
  },
  // ****
  {
    id: 'enenra',
    name: 'Enenra',
    parents: ['White Widow XXL', 'Ghost Train Haze'],
    link: null,
  },
  // ****
  // commented out until I know the actual linage here
  {
    id: 'fuego-verde',
    name: 'Fuego Verde',
    parents: ['Bubba Trouble x Gorilla Girl XL', 'Serial Crusher Theory'],
    link: null,
  },
  {
    id: 'bubba-trouble-x-gorilla-girl-xl',
    name: 'Bubba Trouble x Gorilla Girl XL',
    parents: ['Bubba Trouble', 'Gorilla Girl XL'],
    link: null,
  },
  // // ****
  {
    id: 'gum-tree',
    name: 'Gum Tree',
    parents: ['Hubbabubbasmelloscope', 'White Widow XXL'],
    link: null,
  },
  // ****
  {
    id: 'high-strangeness',
    name: 'High Strangeness',
    parents: ['Serial Crusher Theory', 'UFO x Zipolite Oaxacan'],
    link: null,
  },
  // ****
  {
    id: 'inspector-cheese',
    name: 'Inspector Cheese',
    parents: ['White Cheese Auto', 'Enenra'],
    link: null,
  },
  // ****
  {
    id: 'jack-in-the-box',
    name: 'Jack in the Box',
    parents: ['Black Jack Auto', 'Blue Ox'],
    link: null,
  },
  // ****
  {
    id: 'punch-drunk',
    name: 'Punch Drunk',
    parents: ['Bum Wine', 'Serial Crusher Theory'],
    link: null,
  },
  // ****
  {
    id: 'rolling-thunder',
    name: 'Rolling Thunder',
    parents: ['Bubba Trouble', 'High Strangeness'],
    link: null,
  },
  // ****
  {
    id: 'rope-a-dope',
    name: 'Rope a Dope',
    parents: ['Funkadelic', 'The Ripple x Punch Drunk'],
    link: null,
  },
  // ****
  {
    id: 'the-ripple-x-punch-drunk',
    name: 'The Ripple x Punch Drunk',
    parents: ['The Ripple', 'Punch Drunk'],
    link: null,
  },
  // ****
  {
    id: 'sentinel',
    name: 'Sentinel',
    parents: ['Alien vs Triangle', 'White Widow XXL'],
    link: null,
  },
  // ****
  {
    id: 'serendipity',
    name: 'Serendipity',
    parents: ['Static Flow x New Normal', 'Bum Wine'],
    link: null,
  },
  // ****
  {
    id: 'static-flow-x-new-normal',
    name: 'Static Flow x New Normal',
    parents: ['Static flow', 'New Normal'],
    link: null,
  },
  // ****
  {
    id: 'static-flow',
    name: 'Static Flow',
    parents: ['White Stomper', 'Chikara'],
    link: null,
  },
  // ****
  {
    id: 'serial-crusher-theory',
    name: 'Serial Crusher Theory',
    parents: ['White Triangle', 'White Stomper'],
    link: null,
  },
  // ****
  {
    id: 'smoke',
    name: 'Smoke',
    parents: ['The Ripple', 'High Strangeness'],
    link: null,
  },
  // ****
  {
    id: 'the-ripple',
    name: 'The Ripple',
    parents: ['Bum Wine', 'Grape Crinkle x Tyrone Stomper'],
    link: null,
  },
  // ****
  {
    id: 'xanthanite',
    name: 'Xanthanite',
    parents: ['Troubled Grape', 'High Strangeness'],
    link: null,
  },
  // ****
  // this is a photo
  // {
  //   id: 'yeyo',
  //   name: 'Yeyo',
  //   parents: ['Zapotec', 'The White'],
  //   link: null,
  // },
  // ****
];
