export interface Person {
  collection?: 'Medicinal' | 'Reserva' | 'Limited' | 'Illuminato' | 'Original' | 'Artisanal';
  id: string;
  link: string | null;
  name: string;
  parents: string[];
  featured?: boolean | null;
}

export const peopleData: Person[] = [
  {
    id: 'dawgwalker',
    name: 'Dawgwalker',
    parents: ['Stardawg', 'Skywalker Auto'],
    link: null,
  },
  // ****
  {
    id: 'dawgwalker-f3',
    name: 'Dawgwalker F3',
    parents: ['Dawgwalker', 'Dawgwalker'],
    link: null,
  },
  // ****

  {
    id: 'sour-perrozoso',
    name: 'Sour Perrozoso',
    parents: ['Dawgwalker F3', 'Dawgwalker F3'],
    link: 'https://www.alebrijegenetics.com/cultivarguide/sour-perrozoso',
  },
  // ****
  {
    id: 'blue-zorroso',
    name: 'Blue Zorroso',
    parents: ['Blueberry Slushy', 'Dawgwalker'],
    link: 'https://www.alebrijegenetics.com/cultivarguide/blue-zorroso',
  },
  // ****
  {
    id: 'salmoso-og',
    name: 'Salmoso OG',
    parents: ['Emerald Fire OG', 'Dawgwalker'],
    link: 'https://www.alebrijegenetics.com/cultivarguide/rhx8uet747e1weygzevdggu6trpxlk',
  },
  // ****
  {
    id: 'sticky-sapollo',
    name: 'Sticky Sapollo',
    parents: ['Alien OG', 'Dawgwalker'],
    link: 'https://www.alebrijegenetics.com/cultivarguide/sticky-sapollo',
  },
  // ****
  {
    id: 'balanced-gatuga',
    name: 'Balanced Gatuga',
    parents: ['Red Pure CBD', 'Dawgwalker'],
    link: 'https://www.alebrijegenetics.com/cultivarguide/balanced-gatuga',
  },
  // ****
  {
    id: 'colibreno-funk',
    name: 'Colibreno Funk',
    parents: ['Gouduh', 'Dawgwalker'],
    link: 'https://www.alebrijegenetics.com/cultivarguide/colibreno-funk',
  },
  // ****
  {
    id: 'holidazed-hussler',
    name: 'Holidazed Hussler',
    parents: ['Hussle Berry', 'Dawgwalker'],
    link: null,
  },
  // ****
  {
    id: 'sour-gingerbread',
    name: 'Sour Gingerbread',
    parents: ['True MAC', 'Dawgwalker'],
    link: null,
  },
  // ****
  {
    id: 'cbg-dawg',
    name: 'CBG Dawg',
    parents: ["Hoku's CBG Auto", 'Dawgwalker'],
    link: null,
  },
  // ****
  {
    id: 'dawg-twist',
    name: 'Dawg Twist',
    parents: ['Twisted Menagerie', 'Dawgwalker'],
    link: null,
  },
  // ****
  {
    id: 'dawg-snacks',
    name: 'Dawg Snacks',
    parents: ['Milk and Qookies', 'Dawgwalker'],
    link: null,
  },
  // ****
  {
    id: 'cosmic-pulpato',
    name: 'Cosmic Pulpato',
    parents: ['Star Krunch', 'Dawgwalker'],
    link: null,
  },
  // ****
  // ****
  // ****
  // Juicy Axoloro
  {
    id: 'juicy-axoloro',
    name: 'Juicy Axoloro',
    parents: ['Durban Sunrise', 'Zweet Auto'],
    link: 'https://www.alebrijegenetics.com/cultivarguide/juicy-axoloro',
  },
  // ****
  {
    id: 'tangy-tibuguana',
    name: 'Tangy Tibuguana',
    parents: ['Amensia Haze Auto', 'Juicy Axoloro'],
    link: 'https://www.alebrijegenetics.com/cultivarguide/tangy-tibuguana',
  },
  // ****
  {
    id: 'juicy-scoops',
    name: 'Juicy Scoops',
    parents: ['Grape Rolex x Purple Scoops', 'Juicy Axoloro'],
    link: null,
  },
  // ****

  {
    id: 'juicy-kwayne',
    name: 'Juicy Kwayne',
    parents: ['Bubblegum Queen', 'Juicy Axoloro'],
    link: null,
  },
  // ****

  {
    id: 'piney-pirata',
    name: 'Piney Pirata',
    parents: ["Miracle Berry x Coal Miner's Daughter", 'Juicy Axoloro'],
    link: null,
  },
  // ****
  // ****
  // ****
  // ****
  // Steves Purple Acid (purple nuggets crosses)
  {
    id: 'steves-purple-acid',
    name: "Steve's Purple Acid",
    parents: ['Purple Microdot', 'Purple Nuggets'],
    link: null,
  },
  // ****
  {
    id: 'purple-nuggets-s1',
    name: 'Purple Nuggets S1',
    parents: ['Purple Nuggets', 'Purple Nuggets'],
    link: null,
  },
  // ****
  {
    id: 'purple-zorroso',
    name: 'Purple Zorroso',
    parents: ['Blue Zorroso', 'Purple Nuggets S1'],
    link: null,
  },
  // ****
  {
    id: 'purple-perrozoso',
    name: 'Purple Perrozoso',
    parents: ['Sour Perrozoso', 'Purple Nuggets S1'],
    link: null,
  },
  // ****
  {
    id: 'purple-macaroni',
    name: 'Purple Macaroni',
    parents: ["True MAC x Bobby's Widow", 'Purple Nuggets S1'],
    link: null,
  },
  {
    id: 'true-mac-bobbys-widow',
    name: "True MAC x Bobby's Widow",
    parents: ['True MAC', "Bobby's Widow"],
    link: null,
  },
  // ****
  {
    id: 'lou-ferrigno',
    name: 'Lou Ferrigno',
    parents: ['Bruce Banner #3', 'Purple Nuggets S1'],
    link: null,
  },
  // ****
  {
    id: 'purple-stateradiol',
    name: 'Purple Stateradiol',
    parents: ['Blackberry Kush CBD', 'Purple Nuggets S1'],
    link: null,
  },
  // ****
  {
    id: 'purple-cbg-dawg',
    name: 'Purple CBG Dawg',
    parents: ['CBG Dawg', 'Purple Nuggets S1'],
    link: null,
  },
  // ****
  {
    id: 'jacks-frost',
    name: "Jack's Frost",
    parents: ['Jimmyjacks', 'Purple Nuggets S1'],
    link: null,
  },
  // ****
  // ****
  // ****
  // ****
  // Steves Purple Acid (Steves Purlple Crosses)
  {
    id: 'dazed-cebrana',
    name: 'Dazed Cebrana',
    parents: ['Malawi x Northern Lights', "Steve's Purple Acid"],
    link: 'https://www.alebrijegenetics.com/cultivarguide/dazed-cebrana',
  },
  // ****
  {
    id: 'purple-panterana',
    name: 'Purple Panteraña',
    parents: ['Purple Rocberry', "Steve's Purple Acid"],
    link: 'https://www.alebrijegenetics.com/cultivarguide/purple-panteraa',
  },
  // ****
  {
    id: 'drowsy-lobaca',
    name: 'Drowsy Lobaca',
    parents: ['Milk and Qookies', "Steve's Purple Acid"],
    link: null,
  },
  // ****
  // ****
  // ****
  // ****
  // ****
  // Purple Microdot crosses
  {
    id: 'nancy-ray-gun',
    name: 'Nancy Ray Gun',
    parents: ['Sour Gingerbread', 'Purple Microdot'],
    link: 'https://www.alebrijegenetics.com/cultivarguide/nancy-ray-gun',
  },
  // ***
  {
    id: 'tripping-zorroso',
    name: 'Tripping Zorroso',
    parents: ['Blue Zorroso', 'Purple Microdot'],
    link: null,
  },
  // ***
  {
    id: 'crack-n-acid',
    name: 'Crack & Acid',
    parents: ['Sour Crack', 'Purple Microdot'],
    link: null,
  },
  // ***
  // ***
  // ***
  // ***
  // Juicy Kwayne
  {
    id: 'permanent-holiday',
    name: 'Permanent Holiday',
    parents: ['Lemongrass x Desfran', 'Juicy Kwayne'],
    link: null,
  },
  {
    id: 'indios-verdes',
    name: 'Indios Verdes',
    parents: ['Autowreck (Arcata)', 'Juicy Kwayne'],
    link: null,
  },
  /// ****
  /// ****
  // Tangy Tibuguana Crosses
  {
    id: 'tangy-rbx',
    name: 'Tangy RBX',
    parents: ['Amnesia Haze', 'Tangy Tibuguana'],
    link: null,
  },
  {
    id: 'tangy-mixta',
    name: 'Tangy Mixta',
    parents: ['Vidamints CBD', 'Tangy Tibuguana'],
    link: null,
  },
  {
    id: 'tangy-faux-toe',
    name: 'Tangy Faux Toe',
    parents: ['Papaya MelonZ', 'Tangy Tibuguana'],
    link: null,
  },
  {
    id: 'west-coast-tucadillo',
    name: 'West Coast Tucadillo',
    parents: ['Marathon OG', 'Tangy Tibuguana'],
    link: null,
  },
  {
    id: 'chem-labrata',
    name: 'Chem Labrata',
    parents: ['Sour Perrozoso', 'Marathon OG'],
    link: null,
  },
  {
    id: 'blue-zormiga',
    name: 'Blue Zormiga',
    parents: ['Blue Zorroso', 'Marathon OG'],
    link: null,
  },
];
