export interface Person {
  collection?: 'Medicinal' | 'Reserva' | 'Limited' | 'Illuminato' | 'Original' | 'Artisanal';
  id: string;
  link: string | null;
  name: string;
  parents: string[];
  featured?: boolean | null;
}

export const peopleData: Person[] = [
  // *** Breeding Stock
  {
    id: 'funeral-desserts',
    name: 'Funeral Desserts',
    parents: ['Strawberry Cheesecake', "Bobby's Widow"],
    link: null,
  },
  // ***
  {
    id: 'odensons',
    name: 'OdenSons',
    parents: ['Thors Hammer XXL', 'Loki OG'],
    link: 'https://greenguygenetics.bigcartel.com/product/odensons',
  },
  // ***

  {
    id: 'asgardian-wedding',
    name: 'Asgardian Wedding',
    parents: ['Wedding Cheesecake', 'Loki OG'],
    link: 'https://greenguygenetics.bigcartel.com/product/asgardian-wedding-f4',
  },
  // ***

  {
    id: 'asgardian-funeral',
    name: 'Asgardian Funeral',
    parents: ['Funeral Desserts', 'Loki OG'],
    link: null,
  },
  // ***

  {
    id: 'fenrir',
    name: 'Fenrir',
    parents: ['Purple Rocberry', 'Loki OG'],
    link: null,
  },
  // ***

  {
    id: 'all-fruit',
    name: 'All Fruit',
    parents: ['Tutti Fruity', 'Asgardian Funeral'],
    link: 'https://greenguygenetics.bigcartel.com/product/all-fruit',
  },
  // ***

  {
    id: 'widows-dessert',
    name: 'Widows Dessert',
    parents: ['White Widow XXL', 'Funeral Desserts'],
    link: null,
  },
  // ***

  {
    id: 'blueberry-wine',
    name: 'Blueberry Wine',
    parents: ['BGG Blueberry', 'Fenrir'],
    link: null,
  },
  // ***

  {
    id: 'odens-wolf',
    name: 'Odens Wolf',
    parents: ['OdenSons', 'Fenrir'],
    link: 'https://greenguygenetics.bigcartel.com/product/odens-wolf-f3',
  },
  // ***

  {
    id: 'truck-stop',
    name: 'Truck Stop',
    parents: ['Skywalker OG x Creme de la Chem', 'Odens Wolf'],
    link: 'https://greenguygenetics.bigcartel.com/product/truck-stop',
  },
  // ***

  {
    id: 'odens-express',
    name: 'Odens Express',
    parents: ['OdenSons', 'Zamaldelica Express'],
    link: 'https://greenguygenetics.bigcartel.com/product/odens-express',
  },
  // ***

  {
    id: 'egyptian-widow',
    name: 'Egyptian Widow',
    parents: ['Egyptian Auto x White Widow', 'White Wedding'],
    link: 'https://greenguygenetics.bigcartel.com/product/egyptian-widow',
  },
  // ***

  {
    id: 'the-broken-leg',
    name: 'The Broken Leg',
    parents: ['Fenrir', 'MandoFruit'],
    link: 'https://greenguygenetics.bigcartel.com/product/the-broken-leg-f3',
  },
  // ***

  {
    id: 'tangy-wedding',
    name: 'Tangy Wedding',
    parents: ['Tangy Tibuguana', 'Asgardian Wedding'],
    link: 'https://greenguygenetics.bigcartel.com/product/tangy-wedding',
  },
  // ***

  {
    id: 'og-wedding',
    name: 'OG Wedding',
    parents: ['Marathon OG', 'Asgardian Wedding'],
    link: 'https://greenguygenetics.bigcartel.com/product/og-wedding',
  },
  // ***

  {
    id: 'strawberry-wedding',
    name: 'Strawberry Wedding',
    parents: ['Strawberry Spoons', 'Asgardian Wedding'],
    link: null,
  },
  // ***

  {
    id: 'frogetful-wedding',
    name: 'Frogetful Wedding',
    parents: ['Amnesia Haze', 'Asgardian Wedding'],
    link: 'https://greenguygenetics.bigcartel.com/product/frogetful-wedding',
  },
  // ***

  {
    id: 'odens-wedding',
    name: 'Odens Wedding',
    parents: ['OdenSons', 'Asgardian Wedding'],
    link: null,
  },
  // ***

  {
    id: 'white-wedding',
    name: 'White Wedding',
    parents: ['White Widow XXL', 'Asgardian Wedding'],
    link: null,
  },
  // ***

  {
    id: 'blueberry-grape',
    name: 'Blueberry Grape',
    parents: ['Double Grape', 'Blueberry'],
    link: 'https://greenguygenetics.bigcartel.com/product/blueberry-grape-f3',
  },
  // ***
  {
    id: 'blueberry-grape-f3',
    name: 'Blueberry Grape F3',
    parents: ['Blueberry Grape', 'Blueberry Grape'],
    link: 'https://greenguygenetics.bigcartel.com/product/blueberry-grape-f3',
  },
  // ***

  {
    id: 'blueberry-grape-sour-citrus',
    name: 'Blueberry Grape (Sour Citrus)',
    parents: ['Blueberry Grape F3', 'Blueberry Grape F3'],
    link: 'https://greenguygenetics.bigcartel.com/product/blueberry-grape-f5-citrus-pheno',
  },
  // ***
  {
    id: 'funeral-desserts-f2',
    name: 'Funeral Desserts F2',
    parents: ['Funeral Desserts', 'Funeral Desserts'],
    link: 'https://greenguygenetics.bigcartel.com/product/funeral-desserts-pink-pheno',
  },
  // ***

  {
    id: 'funeral-desserts-pink-pheno',
    name: 'Funeral Desserts (Pink)',
    parents: ['Funeral Desserts F2', 'Funeral Desserts F2'],
    link: null,
  },
  // ***

  {
    id: 'frogception-v1',
    name: 'FrogCeption v1',
    parents: ['Dazed Cebrana', 'Frogetful Wedding'],
    link: 'https://greenguygenetics.bigcartel.com/product/frogception-v1',
  },
  // ***
  {
    id: 'the-sawce',
    name: 'The Sawce',
    parents: ['Cherry Pie', 'OdenSons'],
    link: 'https://greenguygenetics.bigcartel.com/product/the-sawce',
  },
  // ***
  // {
  //   id: 'blueberry-sawce',
  //   name: 'Blueberry Sawce',
  //   parents: ['Blueberry Muffin', 'The Sawce'],
  //   link: 'https://greenguygenetics.bigcartel.com/product/blueberry-sawce',
  //   // notes: 'Photo Fems',
  // },
  // ***
  // {
  //   id: 'apple-sawce',
  //   name: 'Apple Sawce',
  //   parents: ['Apples and Bananas', 'The Sawce'],
  //   link: 'https://greenguygenetics.bigcartel.com/product/apple-sawce',
  //   // notes: 'Photo Fems',
  // },
  // ***
  // {
  //   id: 'white-sawce',
  //   name: 'White Sawce',
  //   parents: ['The Cream', 'The Sawce'],
  //   link: 'https://greenguygenetics.bigcartel.com/product/the-white-sawce',
  //   notes: 'Photo Fems',
  // },
  // ***
  {
    id: 'sungod',
    name: 'Sungod',
    parents: ['Slimegod', 'Arise'],
    link: null,
  },
  // ***
  {
    id: 'ndt-1-1',
    name: 'NDT 1-1',
    parents: ['Ophilias Desserts', 'Sungod'],
    link: null,
  },
  // ***
  // This is a photo reg
  // {
  //   id: 'super-sungod',
  //   name: 'Super Sungod',
  //   parents: ['Super Sativa', 'Sungod'],
  //   link: 'https://greenguygenetics.bigcartel.com/product/super-sungod',
  // },
  // ***
];
