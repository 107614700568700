export interface Person {
  collection?: 'Medicinal' | 'Reserva' | 'Limited' | 'Illuminato' | 'Original' | 'Artisanal';
  id: string;
  link: string | null;
  name: string;
  parents: string[];
  featured?: boolean | null;
}

export const peopleData: Person[] = [
  {
    id: 'marathon-og',
    name: 'Marathon OG',
    parents: ['Marathon OG (Clone)', "Wizard's Apprentice"],
    link: 'https://nightowlseeds.com/marathon-og-auto/',
  },
  {
    id: 'amulet-og',
    name: 'Amulet OG',
    parents: ['Sour Stomper', 'Marathon OG'],
    link: 'https://nightowlseeds.com/product/amulet-og-3/',
    featured: false,
  },
  {
    id: 'bruce-banner-3',
    name: 'Bruce Banner #3',
    parents: ['Bruce Banner #3 (Clone)', 'Forum Stomper'],
    link: 'https://nightowlseeds.com/bruce-banner-3-auto/',
  },
  {
    id: 'gammathon',
    name: 'Gammathon',
    parents: ['Bruce Banner #3', 'Marathon OG'],
    link: null,
  },
  {
    id: 'midnight-marathon',
    name: 'Midnight Marathon',
    parents: ['Marathon OG (Midnight Purple Pheno)', 'Marathon OG'],
    link: 'https://nightowlseeds.com/product/midnight-marathon-f4/',
    featured: false,
  },
  {
    id: 'midnight-mass',
    name: 'Midnight Mass',
    parents: ['Marathon OG (Midnight Purple Pheno)', 'Marathon OG'],
    link: null,
  },
  {
    id: 'valencia-kush',
    name: 'Valencia Kush',
    parents: ['Orange Diesel', 'Marathon OG'],
    link: 'https://nightowlseeds.com/product/valencia-kush-3/',
    featured: false,
  },
];
