import { peopleData } from './PhotonData';
import BreederStrainWeb from './BreederStrainWeb';
import { type WebConfig } from './BreederStrainWeb';

const WEB_CONFIG: WebConfig = {
  breederName: 'Photon',
  parentStrainColor: '#212830',
  childStrainColor: '#a01400',
  featuredColor: '#f5c014',
  nodeColorScaleRgb: 'rgb(209, 175, 74)',
  viewBoxHeight: 3050,
  viewBoxWidth: 3150,
  // Dropdown/url param/etc
  defaultStrainUrlParam: 'double-grape-slurricane',
  defaultStrainName: 'Double Grape Slurricane',
  defaultDropdownValue: {
    label: 'Double Grape Slurricane',
    value: 'Double Grape Slurricane',
    link: null,
  },
};

const Photon = () => (
  <div className="bg-[#7d9968] w-full h-screen overflow-auto font-brandon">
    <BreederStrainWeb people={peopleData} webConfig={WEB_CONFIG} />
  </div>
);

export default Photon;
