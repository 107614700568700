import { peopleData } from './DarkOwlData';
import BreederStrainWeb from './BreederStrainWeb';
import { type WebConfig } from './BreederStrainWeb';

const WEB_CONFIG: WebConfig = {
  breederName: 'Dark Owl',
  parentStrainColor: '#263f83',
  childStrainColor: '#a13f97',
  featuredColor: '#f7c113',
  nodeColorScaleRgb: 'rgb(69, 119, 250)',
  viewBoxHeight: 2550,
  viewBoxWidth: 2550,
  // Dropdown/url param/etc
  defaultStrainUrlParam: 'marathon-og',
  defaultStrainName: 'Marathon OG',
  defaultDropdownValue: {
    label: 'Marathon OG',
    value: 'Marathon OG',
    link: 'https://nightowlseeds.com/marathon-og-auto/',
  },
  nodeCollisionRadiusOverride: 150,
};

const DarkOwl = () => (
  <div className="bg-[#70919a] w-full h-screen overflow-auto font-brandon">
    <BreederStrainWeb people={peopleData} webConfig={WEB_CONFIG} />
  </div>
);

export default DarkOwl;
