export interface Person {
  collection?: 'Medicinal' | 'Reserva' | 'Limited' | 'Illuminato' | 'Original' | 'Artisanal';
  id: string;
  link: string | null;
  name: string;
  parents: string[];
  featured?: boolean | null;
}

export const peopleData: Person[] = [
  {
    id: 'chem-91-chemdogging',
    name: 'Chem 91 x Chemdogging',
    parents: ['Chem 91', 'Chemdogging'],
    link: null,
  },
  {
    id: '91-bananas',
    name: '91 Bananas',
    parents: ['Banana OG', 'Chem 91 x Chemdogging'],
    link: 'https://nightowlseeds.com/91-bananas/',
  },
  {
    id: '91-berries',
    name: '91 Berries',
    parents: ['Cookie Smasher', 'Chem 91 F4'],
    link: null,
  },
  {
    id: 'alchemists-apprentice',
    name: "Alchemist's Apprentice",
    parents: ['Foxy Purps', 'Chem 91 F4'],
    link: null,
  },
  {
    id: 'americhem-dream',
    name: 'Americhem Dream',
    parents: ['Star Spangled Banner #3', 'Chem 91 F4'],
    link: null,
  },
  // ***
  {
    id: 'americhem-dream-remix',
    name: 'Americhem Dream Remix',
    parents: ['Chem 91 F4', 'Star Spangled Banner #3'],
    link: null,
    featured: false,
  },
  {
    id: 'a-crinkle-in-time',
    name: 'A Crinkle in Time',
    parents: ['Sour Crinkle', 'Blue Microverse'],
    link: 'https://nightowlseeds.com/a-crinkle-in-time/',
  },
  {
    id: 'blue-light-special',
    name: 'Blue Light Special',
    parents: ['Blue Microverse', "Wizard's Apprentice"],
    link: 'https://nightowlseeds.com/a-crinkle-in-time/',
  },
  {
    id: 'blue-microverse',
    name: 'Blue Microverse',
    parents: ['24 Carat', 'Blue Magoo BX2'],
    link: 'https://nightowlseeds.com/blue-microverse/',
  },
  {
    id: 'blue-microverse-f4',
    name: 'Blue Microverse F4',
    parents: ['Blue Microverse', 'Blue Microverse'],
    link: 'https://nightowlseeds.com/blue-microverse/',
  },
  {
    id: 'blue-microverse-f5',
    name: 'Blue Microverse F5',
    parents: ['Blue Microverse F4', 'Blue Microverse F4'],
    link: 'https://nightowlseeds.com/blue-microverse-f5/',
  },
  {
    id: 'blue-sprayed-shoes',
    name: 'Blue Sprayed Shoes',
    parents: ['Blue Microverse F4', 'Tyrone Stomper F6'],
    link: 'https://nightowlseeds.com/blue-sprayed-shoes/',
  },
  {
    id: 'blue-sprayed-shoes-remix',
    name: 'Blue Sprayed Shoes Remix',
    parents: ['Blue Microverse', 'Tyrone Stomper'],
    link: 'https://nightowlseeds.com/blue-sprayed-shoes-remix/',
  },
  {
    id: 'bruce-banner-3-auto',
    name: 'Bruce Banner #3',
    parents: ['Bruce Banner #3', 'Forum Stomper'],
    link: 'https://nightowlseeds.com/bruce-banner-3-auto/',
  },
  {
    id: 'bubble-gum-shoe',
    name: 'Bubble Gum Shoe',
    parents: ['Hubbabubbasmelloscope', 'Tyrone Stomper'],
    link: 'https://nightowlseeds.com/bubble-gum-shoe/',
  },
  {
    id: 'bubble-gum-queen',
    name: 'Bubble Gum Queen',
    parents: ['HubbaBubba Haze', 'Cosmic Queen'],
    link: 'https://nightowlseeds.com/bubblegum-queen/',
  },
  {
    id: 'cheech-biggums',
    name: 'Cheech Biggums',
    parents: ["Wizard's Apprentice", 'Tyrone Stomper F6'],
    link: 'https://nightowlseeds.com/cheech-biggums/',
  },
  {
    id: 'chem-91-f4',
    name: 'Chem 91 F4',
    parents: ['Chem 91', 'Chem 91'],
    link: null,
  },
  {
    id: 'chem-candy',
    name: 'Chem Candy',
    parents: ['Chem 91', 'Chemdogging F3'],
    link: 'https://nightowlseeds.com/chem-candy/',
  },
  {
    id: 'chem-n-kush',
    name: "Chem 'n Kush",
    parents: ['Chem 91 F4', 'Pre 98 Episode 1'],
    link: null,
  },
  // Misc
  {
    id: 'chemdogging-f3',
    name: 'Chemdogging F3',
    parents: ['Chemdogging', 'Chemdogging'],
    link: null,
  },
  // Round two, fight
  {
    id: 'chemmunity-service',
    name: 'Chemmunity Service',
    parents: ['Chem 91', 'Chemdogging F3'],
    link: 'https://nightowlseeds.com/chemmunity-service/',
  },
  {
    id: 'chem-cleaner',
    name: 'Chem Cleaner',
    parents: ['Chem 91', 'Chemdogging'],
    link: 'https://nightowlseeds.com/chem-cleaner/',
  },
  {
    id: 'chipped-toof',
    name: 'Chipped Toof',
    parents: ['Toof Decay x Tyrone Stomper', 'Crinkled Cookies'],
    link: 'https://nightowlseeds.com/product/chipped-toof/',
    featured: false,
  },
  {
    id: 'citrus-stomper',
    name: 'Citrus Stomper',
    parents: ['Orange Diesel', 'Tyrone Stomper'],
    link: 'https://nightowlseeds.com/citrus-stomper/',
  },
  {
    id: 'cocoa-chem',
    name: 'Cocoa Chem',
    parents: ['Chem 91', 'Chemdogging F3'],
    link: 'https://nightowlseeds.com/cocoa-chem/',
  },
  {
    id: 'compton-qoolaid',
    name: 'Compton Qoolaid',
    parents: ['Electric Qoolaid', 'Creme de la Compton'],
    link: null,
  },
  {
    id: 'cosmic-apprentice',
    name: 'Cosmic Apprentice',
    parents: ["Wizard's Apprentice", 'Cosmic Queen'],
    link: 'https://nightowlseeds.com/cosmic-apprentice/',
  },
  {
    id: 'cookies-n-creamix',
    name: "Cookies 'n Creamix",
    parents: ['Royal Stomper Remix', 'Crinkle Cookies'],
    link: 'https://nightowlseeds.com/cookies-n-creamix/',
  },
  {
    id: 'cosmic-queen-f4',
    name: 'Cosmic Queen F4',
    parents: ['Cosmic Queen', 'Cosmic Queen'],
    link: null,
  },
  {
    id: 'cosmic-queen-f4-s1',
    name: 'Cosmic Queen F4 S1',
    parents: ['Cosmic Queen F4', 'Cosmic Queen F4'],
    link: 'https://nightowlseeds.com/cosmic-queen-f4-s1/',
  },
  {
    id: 'cosmic-revival',
    name: 'Cosmic Revival',
    parents: ['Unknown (Little Giant CBD)', 'Cosmic Queen'],
    link: 'https://nightowlseeds.com/cosmic-revival/',
  },
  {
    id: 'cpt-og',
    name: 'CPT OG',
    parents: ['Marathon OG', 'Creme de la Compton'],
    link: 'https://nightowlseeds.com/product/cpt-og-3/',
    featured: false,
  },
  {
    id: 'creme-de-la-cosmos',
    name: 'Creme de la Cosmos',
    parents: ['Creme de la Soul', 'Cosmic Queen F4'],
    link: 'https://nightowlseeds.com/creme-de-la-cosmos/',
  },
  {
    id: 'creme-de-la-soul',
    name: 'Creme de la Soul',
    parents: ['Creme de la Chem', 'Tyrone Stomper'],
    link: 'https://nightowlseeds.com/creme-de-la-soul/',
  },
  {
    id: 'dessert-isle',
    name: 'Dessert Isle',
    parents: ['Toof Decay x Tyrone Stomper', 'Mango Isle'],
    link: 'https://nightowlseeds.com/dessert-isle/',
  },
  // Round three, here we go
  {
    id: 'disappearing-act',
    name: 'Disappearing Act',
    parents: ['Chikara', "Wizard's Apprentice"],
    link: 'https://nightowlseeds.com/disappearing-act/',
  },
  {
    id: 'divinations',
    name: 'Divinations',
    parents: ['Pre 98 Episode 1', "Wizard's Apprentice F5"],
    link: 'https://nightowlseeds.com/product/divinations-3/',
    featured: false,
  },
  // ********
  {
    id: 'electric-qoolaid',
    name: 'Electric Qoolaid',
    parents: ['Blue Microverse', 'Cosmic Queen F4'],
    link: 'https://nightowlseeds.com/electric-qoolaid/',
  },
  // ********
  {
    id: 'foot-cheeze-auto',
    name: 'Foot Cheeze Auto',
    parents: ['Northern Cheese Haze', 'Tyrone Stomper'],
    link: 'https://nightowlseeds.com/foot-cheeze-auto/',
  },
  // ********
  {
    id: 'foxy-purps',
    name: 'Foxy Purps',
    parents: ["Wizard's Apprentice", "Wizard's Apprentice"],
    link: 'https://nightowlseeds.com/foxy-purps/',
  },
  // ********
  {
    id: 'funk-potion',
    name: 'Funk Potion',
    parents: ['Funkadelic', "Wizard's Apprentice"],
    link: 'https://nightowlseeds.com/funk-potion/',
  },
  // ********
  {
    id: 'g-chemo-o',
    name: 'G-Chemo-O',
    parents: ['Chem 91', 'Chemdogging F3'],
    link: 'https://nightowlseeds.com/g-chemo-o/',
  },
  // ********
  {
    id: 'galaxy-brain',
    name: 'Galaxy Brain',
    parents: ['Zamaldelica Express', 'Cosmic Queen'],
    link: 'https://nightowlseeds.com/galaxy-brain/',
  },
  // ********
  {
    id: 'gumball-wizard',
    name: 'Gumball Wizard',
    parents: ['Hubbabubbasmelloscope', "Wizard's Apprentice"],
    link: 'https://nightowlseeds.com/gumball-wizard/',
  },
  // ****
  {
    id: 'goldstriker',
    name: 'Goldstriker',
    parents: ['Chem City Blues x Chemdoggin', 'Star Spangled Banner #3'],
    link: null,
    featured: false,
  },
  // ********
  {
    id: 'head-cheeze',
    name: 'Head Cheeze',
    parents: ['Northern Cheese Haze', 'Cosmic Queen F4'],
    link: 'https://nightowlseeds.com/head-cheeze/',
  },
  // ********
  {
    id: 'heartbreaker',
    name: 'Heartbreaker',
    parents: ['White Triangle x White Stomper', "Wizard's Apprentice"],
    link: 'https://nightowlseeds.com/heartbreaker/',
  },
  {
    id: 'instant-classik',
    name: 'Instant Classik',
    parents: ['Pre 98 Episode 1', 'Marathon OG'],
    link: null,
  },
  // ********
  {
    id: 'judy-gemstone',
    name: 'Judy Gemstone',
    parents: ['Strawberry Nuggets', 'Cosmic Queen F4'],
    link: 'https://nightowlseeds.com/judy-gemstone/',
  },
  // ********
  {
    id: 'karmic-connection',
    name: 'Karmic Connection',
    parents: ['Chem City Blues x Chemdogging', 'Cosmic Queen F4'],
    link: 'https://nightowlseeds.com/karmic-connection/',
  },
  // ********
  {
    id: 'lonely-hearts-club',
    name: 'Lonely Hearts Club',
    parents: ['Heartbreaker', 'Zamaldelica Express'],
    link: 'https://nightowlseeds.com/lonely-hearts-club/',
  },
  // ********
  {
    id: 'makeda',
    name: 'Makeda',
    parents: ['Cosmic Queen F4', 'Purple Pope'],
    link: 'https://nightowlseeds.com/makeda/',
  },
  // ********
  {
    id: 'mambo-sauce',
    name: 'Mambo Sauce',
    parents: ['Mango Smile', "Wizard's Apprentice"],
    link: 'https://nightowlseeds.com/mambo-sauce/',
  },
  // ********
  {
    id: 'mango-isle',
    name: 'Mango Isle',
    parents: ['Mango Smile', 'Zamaldelica Express'],
    link: 'https://nightowlseeds.com/mango-isle/',
  },
  // ********
  {
    id: 'mango-isle-f2',
    name: 'Mango Isle F2',
    parents: ['Mango Isle', 'Mango Isle'],
    link: 'https://nightowlseeds.com/mango-isle-f2/',
  },
  // ********
  {
    id: 'mango-sunrise',
    name: 'Mango Sunrise',
    parents: ['Mango Smile', 'Cosmic Queen F4'],
    link: 'https://nightowlseeds.com/mango-sunrise/',
  },
  // ********
  {
    id: 'mangos-n-chem',
    name: "Mangos 'n Chem",
    parents: ['Chem City Blues', 'Mango Isle'],
    link: 'https://nightowlseeds.com/mangos-n-chem/',
  },
  // ********
  // Round Four
  {
    id: 'marathon-og-auto',
    name: 'Marathon OG Auto',
    parents: ['Marathon OG', "Wizard's Apprentice"],
    link: 'https://nightowlseeds.com/marathon-og-auto/',
  },
  // ********
  {
    id: 'microverse-morsel',
    name: 'Microverse Morsel',
    parents: ['Forgotten Cookies', 'Blue Microverse'],
    link: 'https://nightowlseeds.com/microverse-morsel/',
  },
  {
    id: 'microverse-morsels-v2',
    name: 'Microverse Morsels V2',
    parents: ['Blue Microverse F5', 'Milk and Qookies'],
    link: 'https://nightowlseeds.com/product/microverse-morsels-v2-3/',
    featured: false,
  },
  // ********
  {
    id: 'milk-and-qookies',
    name: 'Milk and Qookies',
    parents: ['Forum Stomper', 'Cosmic Queen F4'],
    link: 'https://nightowlseeds.com/milk-and-qookies/',
  },
  {
    id: 'milk-and-qookies-f2',
    name: 'Milk and Qookies F2',
    parents: ['Milk and Qookies', 'Milk and Qookies'],
    link: 'https://nightowlseeds.com/product/milk-and-qookies-f2-2/',
    featured: false,
  },
  // ********
  {
    id: 'miracle-berry',
    name: 'Miracle Berry',
    parents: ['Zamaldelica Express', 'Blue Microverse'],
    link: 'https://nightowlseeds.com/miracle-berry/',
  },
  // ********
  {
    id: 'miracle-berry-remix',
    name: 'Miracle Berry Remix',
    parents: ['Walter White', 'Tyrone Stomper'],
    link: 'https://nightowlseeds.com/miracle-berry-remix/',
  },
  {
    id: 'northern-cheese-haze',
    name: 'Northern Cheese Haze',
    parents: ['Fantasmo Express', 'Exodus Cheese x NL#5'],
    link: null,
  },
  // ********
  {
    id: 'oh-geeze',
    name: 'Oh Geeze',
    parents: ['3 Bears OG', 'Tyrone Stomper'],
    link: 'https://nightowlseeds.com/oh-geeze/',
  },
  // ********
  {
    id: 'orlandos-magic',
    name: "Orlando's Magic",
    parents: ['Triangle Stomper', "Wizard's Apprentice"],
    link: 'https://nightowlseeds.com/orlandos-magic/',
  },
  // ********
  {
    id: 'outlier-c',
    name: 'Outlier C',
    parents: ['Outlier Z', 'Crinkled Cookies'],
    link: 'https://nightowlseeds.com/outlier-c/',
  },
  {
    id: 'outlier-v',
    name: 'Outlier V',
    parents: ['Outlier C', 'Vanilla Fizz'],
    link: null,
  },
  // ********
  {
    id: 'outlier-z',
    name: 'Outlier Z',
    parents: ['Pre 98 Episode 1', 'Triangle Kush'],
    link: 'https://nightowlseeds.com/outlier-z/',
  },
  // ****
  {
    id: 'peanut-butter-and-crack-sandwich',
    name: 'Peanut Butter and Crack Sandwich',
    parents: ['Tyrone Stomper', 'Tyrone Stomper (Peanut Butter Pheno)'],
    link: 'https://nightowlseeds.com/product/peanut-butter-and-crack-sandwich/',
  },
  // ********
  {
    id: 'pope-banner-iii',
    name: 'Pope Banner III',
    parents: ['Bruce Banner #3', 'Purple Pope'],
    link: 'https://nightowlseeds.com/pope-banner-iii/',
  },
  // ********
  {
    id: 'popesicle',
    name: 'Popesicle',
    parents: ['Tyrone Stomper F6', 'Purple Pope'],
    link: 'https://nightowlseeds.com/popesicle/',
  },
  // ********
  {
    id: 'pre-98-episode-1',
    name: 'Pre 98 Episode 1',
    parents: ['Bubba Kush Pre 98', 'Skywalker'],
    link: 'https://nightowlseeds.com/product/pre-98-episode-1-f4-5/',
  },
  // ********
  {
    id: 'purple-microverse',
    name: 'Purple Microverse',
    parents: ['Purple Pope', 'Blue Microverse'],
    link: 'https://nightowlseeds.com/purple-microverse/',
  },
  // ********
  {
    id: 'purple-pope',
    name: 'Purple Pope',
    parents: ['Anvil', "Wizard's Apprentice"],
    link: 'https://nightowlseeds.com/purple-pope/',
  },
  // ********
  {
    id: 'purple-pope-f2',
    name: 'Purple Pope F2',
    parents: ['Purple Pope', 'Purple Pope'],
    link: 'https://nightowlseeds.com/purple-pope-f2/',
  },
  {
    id: 'purple-strawberry-popesicle',
    name: 'Purple Strawberry Popesicle',
    parents: ['Strawberry Popesicle', 'Cookie Smasher'],
    link: null,
    featured: false,
  },
  // ********
  {
    id: 'queen-cheese-haze',
    name: 'Queen Cheeze Haze',
    parents: ['Northern Cheese Haze', 'Cosmic Queen F4'],
    link: 'https://nightowlseeds.com/queen-cheese-haze/',
  },
  // ********
  {
    id: 'queens-banner',
    name: "Queen's Banner",
    parents: ['Cosmic Queen F4', 'Bruce Banner #3'],
    link: 'https://nightowlseeds.com/queens-banner/',
  },
  // ********
  {
    id: 'roswell-cookies',
    name: 'Roswell Cookies',
    parents: ["Ripley's OG x Tyrone Stomper", 'Crinkled Cookies'],
    link: 'https://nightowlseeds.com/roswell-cookies/',
  },
  // ********
  {
    id: 'royal-stomper',
    name: 'Royal Stomper',
    parents: ['Tyrone Stomper F6', 'Cosmic Queen F4'],
    link: 'https://nightowlseeds.com/royal-stomper/',
  },
  // ********
  {
    id: 'royal-stomper-remix',
    name: 'Royal Stomper Remix',
    parents: ['Cosmic Queen F4', 'Tyrone Stomper'],
    link: 'https://nightowlseeds.com/royal-stomper-remix/',
  },
  // ********
  // ALMOST THERE
  {
    id: 'sage-scout',
    name: 'Sage Scout',
    parents: ['Forum Stomper', "Wizard's Apprentice"],
    link: 'https://nightowlseeds.com/sage-scout/',
  },
  // ********
  {
    id: 'scoopski',
    name: 'Scoopski',
    parents: ['Purple Scoops', "Wizard's Apprentice"],
    link: 'https://nightowlseeds.com/scoopski/',
  },
  // ********
  {
    id: 'shekinah',
    name: 'Shekinah',
    parents: ['Supernatural OG', 'Cosmic Queen'],
    link: 'https://nightowlseeds.com/shekinah/',
  },
  {
    id: 'sirius-blue',
    name: 'Sirius Blue',
    parents: ['Blue Microverse F5', 'Chem 91 F4'],
    link: 'https://nightowlseeds.com/shekinah/',
  },
  // ********
  {
    id: 'space-station-orange',
    name: 'Space Station Orange',
    parents: ['Orange Diesel', 'Cosmic Queen F4'],
    link: 'https://nightowlseeds.com/space-station-orange/',
  },
  // ********
  {
    id: 'space-station-orange-v2',
    name: 'Space Station Orange V2',
    parents: ['Super Orange Haze', 'Cosmic Queen F4'],
    link: 'https://nightowlseeds.com/space-station-orange-v-2/',
  },
  // ********
  {
    id: 'spice-melange',
    name: 'Spice Melange',
    parents: ['Outlier Z', 'Mango Isle'],
    link: 'https://nightowlseeds.com/spice-melange/',
    featured: false,
  },
  // ********
  {
    id: 'spotless-mind',
    name: 'Spotless Mind',
    parents: ['Cosmic Queen F4', 'Fugue Stage'],
    link: 'https://nightowlseeds.com/spotless-mind/',
  },
  // ********
  {
    id: 'squanch-queen',
    name: 'Squanch Queen',
    parents: ['Cosmic Queen F4', 'Samsquanch OG'],
    link: 'https://nightowlseeds.com/squanch-queen/',
  },
  // ********
  {
    id: 'squanch-stomper',
    name: 'Squanch Stomper',
    parents: ['Samsquanch OG', 'Tyrone Stomper F6'],
    link: 'https://nightowlseeds.com/squanch-stomper/',
  },
  // ********
  {
    id: 'star-krunch',
    name: 'Star Krunch',
    parents: ['Cosmic Queen F4', 'Toof Decay'],
    link: 'https://nightowlseeds.com/star-krunch/',
  },
  // ********
  {
    id: 'star-spangled-banner-3',
    name: 'Star Spangled Banner #3',
    parents: ['Bruce Banner #3', 'Blue Microverse'],
    link: 'https://nightowlseeds.com/star-spangled-banner-3/',
  },
  // ********
  {
    id: 'starfox',
    name: 'Starfox',
    parents: ['Foxy Purps', 'Cosmic Queen'],
    link: 'https://nightowlseeds.com/starfox/',
  },
  {
    id: 'strawberry-popesicle',
    name: 'Strawberry Popesicle',
    parents: ['Popesicle', 'Cookie Smasher'],
    link: null,
  },
  // ***
  {
    id: 'strawberry-cheesequake',
    name: 'Strawberry Cheesequake',
    parents: ['Strawberry Milk and Qookies', 'Strawberry Milk and Qookies (Cheesy Fruit Pheno)'],
    link: 'https://nightowlseeds.com/product/milk-and-qookies-f2/',
    featured: false,
  },
  // ********
  {
    id: 'super-cyan-haze',
    name: 'Super Cyan Haze',
    parents: ['Supernatural OG', 'Blue Microverse'],
    link: 'https://nightowlseeds.com/super-cyan-haze/',
  },
  // ********
  {
    id: 'superational',
    name: 'Superational',
    parents: ['Cosmic Queen F4', 'Supernatural OG'],
    link: 'https://nightowlseeds.com/superational/',
  },
  // ********
  {
    id: 'the-goldilocks-zone',
    name: 'The Goldilocks Zone',
    parents: ['Double Grape x 3 Bears OG', 'Blue Microverse'],
    link: 'https://nightowlseeds.com/the-goldilocks-zone/',
  },
  // ********
  {
    id: 'the-mandela-effect',
    name: 'The Mandela Effect',
    parents: ['Fugue State', 'Mango Isle'],
    link: 'https://nightowlseeds.com/the-mandela-effect/',
  },
  // ********
  {
    id: 'trade-bait',
    name: 'Trade Bait',
    parents: ['Double Grape', "Wizard's Apprentice"],
    link: 'https://nightowlseeds.com/trade-bait/',
  },
  // ********
  {
    id: 'triangle-stomper',
    name: 'Triangle Stomper',
    parents: ['Triangle Kush', 'Tyrone Stomper'],
    link: 'https://nightowlseeds.com/triangle-stomper/',
  },
  // ********
  {
    id: 'triple-grape',
    name: 'Triple Grape',
    parents: ['Double Grape', 'Tyrone Stomper'],
    link: 'https://nightowlseeds.com/triple-grape/',
  },
  // ********
  {
    id: 'tropical-wiz',
    name: 'Tropical Wiz',
    parents: ["Wizard's Apprentice", 'Zamaldelica Express'],
    link: 'https://nightowlseeds.com/tropical-wiz/',
  },
  // ********
  {
    id: 'tropical-wiz-v-2',
    name: 'Tropical Wiz V2',
    parents: ["Wizard's Apprentice", 'Mango Isle'],
    link: 'https://nightowlseeds.com/tropical-wiz-v-2/',
  },
  // ********

  {
    id: 'tyrone-and-sour',
    name: 'Tyrone and Sour',
    parents: ['Sour Stomper', 'Tyrone Stomper'],
    link: 'https://nightowlseeds.com/tyrone-and-sour/',
  },
  // ********

  {
    id: 'tyrone-stomper',
    name: 'Tyrone Stomper',
    parents: ['Tyrone Special', 'Grape Stomper'],
    link: 'https://nightowlseeds.com/tyrone-stomper/',
  },
  // ********

  {
    id: 'tyrone-stomper-f6',
    name: 'Tyrone Stomper F6',
    parents: ['Tyrone Stomper', 'Tyrone Stomper'],
    link: 'https://nightowlseeds.com/tyrone-stomper-f6/',
  },
  // ********
  /// 88888888888
  {
    id: 'vacation-bubba',
    name: 'Vacation Bubba',
    parents: ['Pre 98 Episode 1', 'Mango Isle'],
    link: 'https://nightowlseeds.com/vacation-bubba/',
  },
  {
    id: 'vanilla-fizz',
    name: 'Vanilla Fizz',
    parents: ['White Stomper x Sour Hound', 'Cosmic Queen'],
    link: 'https://nightowlseeds.com/product/vanilla-fizz-f2-2/',
  },
  {
    id: 'vanilla-fizz-f2',
    name: 'Vanilla Fizz F2',
    parents: ['Vanilla Fizz', 'Vanilla Fizz'],
    link: 'https://nightowlseeds.com/product/vanilla-fizz-f2-2/',
    featured: false,
  },
  {
    id: 'white-stomper-v3',
    name: 'White Stomper V3',
    parents: ['Walter White', 'Tyrone Stomper'],
    link: 'https://nightowlseeds.com/white-stomper-v3/',
  },
  // ********
  {
    id: 'wizards-apprentice',
    name: "Wizard's Apprentice",
    parents: ['Cheech Wizard', 'Skywalker'],
    link: 'https://nightowlseeds.com/wizards-apprentice/',
  },
  // ********
  {
    id: 'wizards-apprentice-f5',
    name: "Wizard's Apprentice F5",
    parents: ["Wizard's Apprentice", "Wizard's Apprentice"],
    link: 'https://nightowlseeds.com/wizards-apprentice-f5/',
  },
  // ********

  // Bottom of the list
  {
    id: 'word-of-mouf',
    name: 'Word of Mouf',
    parents: ['Toof Decay', 'Blue Microverse'],
    link: 'https://nightowlseeds.com/word-of-mouf/',
  },
  {
    id: 'zamaldelica-express',
    name: 'Zamaldelica Express',
    parents: ['Zamaldelica', 'Fantasmo Express'],
    link: 'https://nightowlseeds.com/zamaldelica-express/',
  },
  {
    id: 'zamango',
    name: 'ZaMango',
    parents: ['Zamaldelica Express', 'Mango Isle'],
    link: 'https://nightowlseeds.com/zamango/',
  },
  {
    id: 'zamunda-cookies',
    name: 'Zamunda Cookies',
    parents: ['Zamaldelica Express', 'Crinkled Cookies'],
    link: 'https://nightowlseeds.com/zamunda-cookies/',
  },
  // Misc
  // {
  //   id: 'wizards-apprentice',
  //   name: "Wizard's Apprentice",
  //   parents: ['Cheech Wizard', 'Skywalker'],
  //   link: null,
  // },
  {
    id: 'cosmic-queen',
    name: 'Cosmic Queen',
    parents: ['Spacedawg', 'Chemdogging'],
    link: 'https://en.seedfinder.eu/strain-info/Cosmic_Queen/Mephisto_Genetics/',
  },
  // ****
  // *** OWLWEEN 2023
  {
    id: 'chem-n-qoolaid',
    name: "Chem 'n Qoolaid",
    parents: ['Electric Qoolaid', 'Chem 91 F4'],
    link: null,
  },
  // ****
  {
    id: 'harvey-wallbanger',
    name: 'Harvey Wallbanger',
    parents: ['Orange Diesel', 'Cookie Smasher'],
    link: 'https://nightowlseeds.com/product/harvey-wallbanger-3/',
    featured: false,
  },
  // ****
  {
    id: 'mondo-smash',
    name: 'Mondo Smash',
    parents: ['Cookie Smasher', 'Creme de la Compton'],
    link: 'https://nightowlseeds.com/product/mondo-smash-3/',
    featured: false,
  },
  // ****
  {
    id: 'strawberry-milk-and-qookies-remix',
    name: 'Strawberry Milk and Qookies Remix',
    parents: ['Bruce Banner #3', 'Milk and Qookies'],
    link: 'https://nightowlseeds.com/product/strawberry-milk-and-qookies-remix/',
    featured: false,
  },
  // ****
  {
    id: '91-grapes',
    name: '91 Grapes',
    parents: ['Sour Stomper', 'Chem 91 F4'],
    link: null,
  },
  // ****
  // Owltines day 2024
  // *****
  // *****
  // *****
  // *****
  {
    id: 'chem-n-juice-f4',
    name: "Chem 'n Juice F4",
    parents: ['Chem 91', 'Chemdogging'],
    link: 'https://nightowlseeds.com/product/chem-n-juice-f4/',
    featured: false,
  },
  // ****
  {
    id: 'compound-v',
    name: 'Compound V',
    parents: ['Chem City Blues x Chemdogging', 'Vanilla Fizz'],
    link: 'https://nightowlseeds.com/product/compound-v/',
    featured: false,
  },
  // ****
  {
    id: 'chem-city-blues-chemdogging',
    name: 'Chem City Blues x Chemdogging',
    parents: ['Chem City Blues', 'Chemdogging'],
    link: null,
    featured: false,
  },
  // ****
  {
    id: 'grape-deebo',
    name: 'Grape Deebo',
    parents: ['Sour Stomper F4', 'Creme de la Compton'],
    link: 'https://nightowlseeds.com/product/grape-deebo/',
    featured: false,
  },
  {
    id: 'sour-stomper-f4',
    name: 'Sour Stomper F4',
    parents: ['Sour Stomper', 'Sour Stomper'],
    link: null,
    featured: false,
  },
  // ****
  {
    id: 'creme-de-la-compton',
    name: 'Creme de la Compton',
    parents: ['Creme de la Chem', 'Wilson!'],
    link: null,
    featured: false,
  },
  // ****
  {
    id: 'magic-castle',
    name: 'Magic Castle',
    parents: ["Wizard's Apprentice F5", 'Creme de la Compton'],
    link: 'https://nightowlseeds.com/product/magic-castle/',
    featured: false,
  },
  // ****
  {
    id: 'naptown',
    name: 'Naptown',
    parents: ["Ripley's OG x Tyrone Stomper", 'Strawberry Milk and Qookies'],
    link: 'https://nightowlseeds.com/product/naptown/',
    featured: false,
  },
  {
    id: 'strawberry-milk-and-qookies',
    name: 'Strawberry Milk and Qookies',
    parents: ['Milk and Qookies', 'Bruce Banner #3'],
    link: null,
    featured: false,
  },
  // ****
  {
    id: 'ripleys-og-tyrone-stomper',
    name: "Ripley's OG x Tyrone Stomper",
    parents: ["Ripley's OG", 'Tyrone Stomper'],
    link: null,
    featured: false,
  },
  // ****
  {
    id: 'rouge-and-blue',
    name: 'Rogue and Blue',
    parents: ['Blue Microverse', 'Creme de la Compton'],
    link: null,
    featured: false,
  },
  // ****
  {
    id: 'smokeys-stash',
    name: "Smokey's Stash",
    parents: ['Forum Stomper', 'Creme de la Compton'],
    link: null,
    featured: false,
  },
  // ****
  {
    id: 'space-station-gold',
    name: 'Space Station Gold',
    parents: ['Cosmic Queen F4 S1', 'Vanilla Fizz'],
    link: 'https://nightowlseeds.com/product/space-station-gold/',
    featured: false,
  },
  // ****
  {
    id: 'zamaldelica-express-f4s1',
    name: 'Zamaldelica Express F4 S1',
    parents: ['Zamaldelica Express', 'Zamaldelica Express'],
    link: 'https://nightowlseeds.com/product/zamaldelica-express-f4s1/',
    featured: false,
  },
];
