import { peopleData } from './HumminbirdHillsData';
import BreederStrainWeb from './BreederStrainWeb';
import { type WebConfig } from './BreederStrainWeb';

const WEB_CONFIG: WebConfig = {
  breederName: 'Humminbird Hills',
  parentStrainColor: '#ea1bfb',
  childStrainColor: '#a2b0f7',
  featuredColor: '#f5c014',
  nodeColorScaleRgb: 'rgb(92, 242, 192)',
  viewBoxHeight: 4250,
  viewBoxWidth: 4650,
  // Dropdown/url param/etc
  defaultStrainUrlParam: 'strawgoo',
  defaultStrainName: 'Strawgoo',
  defaultDropdownValue: {
    label: 'Strawgoo',
    value: 'Strawgoo',
    link: null,
  },
};

const HumminbirdHills = () => (
  <div className="bg-[#a68a64] w-full h-screen overflow-auto font-brandon">
    <BreederStrainWeb people={peopleData} webConfig={WEB_CONFIG} />
  </div>
);

export default HumminbirdHills;
