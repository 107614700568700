export interface Person {
  collection?: 'Medicinal' | 'Reserva' | 'Limited' | 'Illuminato' | 'Original' | 'Artisanal';
  id: string;
  link: string | null;
  name: string;
  parents: string[];
  featured?: boolean | null;
}

export const peopleData: Person[] = [
  // *** Breeding Stock
  {
    id: 'gouduh',
    name: 'Gouduh',
    parents: ['Whiskey Zulu', 'Foot Cheeze'],
    link: null,
  },
  {
    id: 'gouduh-f2',
    name: 'Gouduh F2',
    parents: ['Gouduh', 'Gouduh'],
    link: null,
  },
  {
    id: 'gamma-cheeze',
    name: 'Gamma Cheeze',
    parents: ['Bruce Banner #3', 'Gouduh'],
    link: null,
  },
  {
    id: 'strawgoo',
    name: 'Strawgoo',
    parents: ['Strawberry Milk and Qookies', 'Gouduh'],
    link: null,
  },
  {
    id: 'ursula-berry',
    name: 'Ursula Berry',
    parents: ['Legend Berry OG', 'Teddy Gramz'],
    link: null,
  },
  {
    id: 'ursula-berry-f2',
    name: 'Ursula Berry F2',
    parents: ['Ursula Berry', 'Ursula Berry'],
    link: null,
  },
  {
    id: 'ursula-berry-f3',
    name: 'Ursula Berry F3',
    parents: ['Ursula Berry F2', 'Ursula Berry F2'],
    link: null,
  },
  {
    id: 'ursula-berry-f4',
    name: 'Ursula Berry F4',
    parents: ['Ursula Berry F3', 'Ursula Berry F3'],
    link: null,
  },
  // ****
  // ****
  // ****
  // ****
  // ****
  // Cultivars
  // ****
  // ****
  // ****
  // ****
  // ****
  {
    id: 'chedduh',
    name: 'Chedduh',
    parents: ['Whiskey Zulu', 'Foot Cheeze'],
    link: null,
  },
  {
    id: 'mango-salsa',
    name: 'Mango Salsa',
    parents: ['Mambo Sauce', 'Foot Cheeze'],
    link: null,
  },
  // ****
  {
    id: 'bubblez',
    name: 'Bubblez',
    parents: ['Zweet Gum Twee', 'Gouduh'],
    link: null,
  },
  // ****
  {
    id: 'desert-eagle',
    name: 'Desert Eagle',
    parents: ['Beo x Grendel', 'Gouduh'],
    link: null,
  },
  // ****
  {
    id: 'falco',
    name: 'Falco',
    parents: ['Star Fox', 'Gouduh'],
    link: null,
  },
  // ****
  {
    id: 'humminbirds-cookies',
    name: "Humminbird's Cookies",
    parents: ["Stony's Strawberries", 'Gouduh'],
    link: null,
  },
  // ****
  {
    id: 'moon-walk',
    name: 'Moon Walk',
    parents: ['Voidwalker', 'Gouduh'],
    link: null,
  },
  // ****
  {
    id: 'planters-punch',
    name: "Planter's Punch",
    parents: ['Passion Punch', 'Gouduh'],
    link: null,
  },
  // ****
  {
    id: 'redline',
    name: 'Redline',
    parents: ['Niteblood', 'Gouduh'],
    link: null,
  },
  // ****
  {
    id: 'ziwi',
    name: 'Ziwi',
    parents: ['El Chemi Kiwi', 'Gouduh'],
    link: null,
  },
  // ****
  // ****
  // ****
  // GAMMA STUFF
  {
    id: 'cyttorak',
    name: 'Cyttorak',
    parents: ['Razzberry Gastank', 'Gamma Cheeze'],
    link: null,
  },

  // ****
  {
    id: 'juggernaut',
    name: 'Juggernaut',
    parents: ['Razzberry Gastank', 'Gamma Cheeze'],
    link: null,
  },
  // ****
  {
    id: 'quantum-sherb',
    name: 'Quantum Sherb',
    parents: ['Holidazed Hussler', 'Gamma Cheeze'],
    link: null,
  },
  // ****
  {
    id: 'chopped-cheese',
    name: 'Chopped Cheese',
    parents: ['NY Diesel', 'Gamma Cheeze'],
    link: null,
  },
  // ****
  {
    id: 'outlier-g',
    name: 'Outlier G',
    parents: ['Outlier C', 'Gamma Cheeze'],
    link: null,
  },
  // ****
  {
    id: 'fromage',
    name: 'Fromage',
    parents: ['Newberry', 'Gamma Cheeze'],
    link: null,
  },
  // ****
  // **** Strawgoo line
  // ****
  {
    id: 'pink-gammanade',
    name: 'Pink Gammanade',
    parents: ['Royal Pink Lemonberries', 'Strawgoo'],
    link: 'https://humminbirdhills.com/products/pink-gammanade-restock',
  },
  // ***
  {
    id: 'red-zorroso',
    name: 'Red Zorroso',
    parents: ['Blue Zorroso', 'Strawgoo'],
    link: null,
  },
  // ***
  {
    id: 'bird-tek',
    name: 'Bird Tek',
    parents: ['Lion Tech', 'Strawgoo'],
    link: null,
  },
  // ***
  {
    id: 'pingoo',
    name: 'Pingoo',
    parents: ['Florida Peaches', 'Strawgoo'],
    link: null,
  },
  // ***
  {
    id: 'nuka-cheese',
    name: 'Nuka Cheese',
    parents: ['Blue Cheese', 'Strawgoo'],
    link: null,
  },
  // ***
  {
    id: 'strawberry-moon',
    name: 'Strawberry Moon',
    parents: ['Cherry Moon', 'Strawgoo'],
    link: 'https://humminbirdhills.com/products/untitled-sep4_16-22',
  },
  // ***
  {
    id: 'sour-taffy',
    name: 'Sour Taffy',
    parents: ['Sour Bear OG', 'Strawgoo'],
    link: null,
  },
  // ***
  {
    id: 'red-kong',
    name: 'Red Kong',
    parents: ['Lion Tech', 'Strawgoo'],
    link: null,
  },
  // ***
  {
    id: 'fiona',
    name: 'Fiona',
    parents: ['Shrek OG #10', 'Strawgoo'],
    link: null,
  },
  // ***
  {
    id: 'pepper',
    name: 'Pepper',
    parents: ['Care Bearz', 'Strawgoo'],
    link: null,
  },
  // ***
  {
    id: 'strawgoo-f2',
    name: 'Strawgoo F2',
    parents: ['Strawgoo #25', 'Strawgoo'],
    link: 'https://humminbirdhills.com/products/strawgoo-f2-restock',
  },
  // ***
  {
    id: 'red-ruby',
    name: 'Red Ruby',
    parents: ["Lucy's Diamonds", 'Strawgoo'],
    link: null,
  },
  // ***
  {
    id: 'ooze',
    name: 'Ooze',
    parents: ['Voidwalker', 'Strawgoo'],
    link: 'https://humminbirdhills.com/products/ooze',
  },
  // ***
  {
    id: 'barrel-roll',
    name: 'Barrel Roll',
    parents: ['Falco', 'Strawgoo'],
    link: 'https://humminbirdhills.com/products/barrel-roll',
  },
  // ***
  {
    id: 'hollow',
    name: 'Hollow',
    parents: ['Desert Eagle', 'Strawgoo'],
    link: null,
  },
  {
    id: 'black-z',
    name: 'Black Z',
    parents: ['Blue Zorroso', 'Strawgoo'],
    link: 'https://humminbirdhills.com/products/black-z',
  },
  {
    id: 'guava-kix',
    name: 'Guava Kix',
    parents: ['White Stomper x Double Grape BX', 'Strawgoo'],
    link: 'https://humminbirdhills.com/products/guava-kix',
  },
  {
    id: 'peach-blossom',
    name: 'Peach Blossom',
    parents: ['Pink Carnation', 'Strawgoo'],
    link: 'https://humminbirdhills.com/products/peach-blossom-1',
  },
  {
    id: 'lime-slurm',
    name: 'Lime Slurm',
    parents: ['Gamma Cheeze', 'Strawgoo'],
    link: null,
  },
  {
    id: 'hallow',
    name: 'Hallow',
    parents: ['Desert Eagle', 'Strawgoo'],
    link: null,
  },
  // ***
  // ***
  // ***
  // Ursula Berry F4
  {
    id: 'nightwing',
    name: 'Nightwing',
    parents: ["Wizard's Apprentice F5", 'Ursula Berry F4'],
    link: null,
  },
  // ***
  {
    id: 'pink-widow',
    name: 'Pink Widow',
    parents: ["Bobby's Banner", 'Ursula Berry F4'],
    link: null,
  },
  // ***
  {
    id: 'boba-tea',
    name: 'Boba Tea',
    parents: ['Balanced Gatuga', 'Ursula Berry F4'],
    link: null,
  },
  // ***
  {
    id: 'calypso',
    name: 'Calypso',
    parents: ['Twisted Puppy', 'Ursula Berry F4'],
    link: null,
  },
  // ***
  // OTHER
  // ***
  // ***
  {
    id: 'lagoon',
    name: 'Lagoon',
    parents: ['Lake Berries', 'Strawgoo'],
    link: 'https://humminbirdhills.com/products/untitled-sep4_16-22',
  },
  // ***
  // ***
  // URSULA F2 F3 ETC
  // ***
  // ***
  {
    id: 'save-me-cheebus',
    name: 'Save Me Cheebus',
    parents: ['Sweet Cheezus', 'Blue Bear OG'],
    link: null,
  },
  // ***
  {
    id: 'frozone',
    name: 'Frozone',
    parents: ['Iced Gushers', 'Awesome Sauce'],
    link: null,
  },
  // ***
  {
    id: 'clawberry',
    name: 'Clawberry',
    parents: ['Black Glaze', 'Ursula Berry F2'],
    link: null,
  },
  // ***
  {
    id: 'harpy',
    name: 'Harpy',
    parents: ["Devil's Anvil", 'Ursula Berry F2'],
    link: null,
  },
  // ***
  {
    id: 'poohs-peaches',
    name: "Pooh's Peaches",
    parents: ['Peach Berry', 'Ursula Berry F2'],
    link: null,
  },
  // ***
  {
    id: 'honey-berry',
    name: 'Honey Berry',
    parents: ['Ursula Berry F2', 'Peach Berry'],
    link: null,
  },
  // ***
  {
    id: 'bellavino',
    name: 'Bellavino',
    parents: ['Ursula Berry F3', 'Ursula Berry F3'],
    link: null,
  },
  // ***
  {
    id: 'hubbabaloo',
    name: 'Hubbabaloo',
    parents: ['Ursula Berry F3', 'Ursula Berry F3'],
    link: null,
  },
  // ***
  {
    id: 'cheese-foam',
    name: 'Cheese Foam',
    parents: ['Cheese-sus Christ', 'Ursula Berry F3'],
    link: null,
  },
  // ***
  {
    id: 'fondoo',
    name: 'Fondoo',
    parents: ['Gouduh', 'Ursula Berry F3'],
    link: null,
  },
  // ***
  {
    id: 'headliner',
    name: 'Headliner',
    parents: ['Disappearing Act', 'Ursula Berry F3'],
    link: null,
  },
  // ***
  {
    id: 'hussle-berry',
    name: 'Hussle Berry',
    parents: ['Marathon OG', 'Ursula Berry F3'],
    link: null,
  },
  // ***
  {
    id: 'peach-farts',
    name: 'Peach Farts',
    parents: ['Double Sour Bubble Guts', "Pooh's Peaches"],
    link: null,
  },
  // ***
  {
    id: 'peach-walk',
    name: 'Peach Walk',
    parents: ['Marathon OG', "Pooh's Peaches"],
    link: null,
  },
  // ***
  {
    id: 'orange-chai',
    name: 'Orange Chai',
    parents: ['Purple Thai', "Pooh's Peaches"],
    link: null,
  },
  // ***
  {
    id: 'origami',
    name: 'Origami',
    parents: ['Crinkle Cut', "Pooh's Peaches"],
    link: null,
  },
  // ***
];
