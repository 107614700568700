import { ChangelogData } from './ChangelogData';
import { useNavigate } from 'react-router-dom';
import { track } from '@vercel/analytics';

import '../../App.css';

const Changelog = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-[#f6ffff] font-brandon flex flex-col items-center pb-10" style={{ minHeight: '100vh' }}>
      <div className="mb-2 mt-4 text-md">
        <span
          className="underline cursor-pointer flex flex-row justify-between font-bold"
          onClick={() => {
            navigate('/');
            track(`Button Clicked: Home - DLI Calculator`);
          }}
        >
          <div className="flex items-center">
            <svg
              className="w-4 h-4 text-gray-800 dark:text-black"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                fillRule="evenodd"
                d="M11.3 3.3a1 1 0 0 1 1.4 0l6 6 2 2a1 1 0 0 1-1.4 1.4l-.3-.3V19a2 2 0 0 1-2 2h-3a1 1 0 0 1-1-1v-3h-2v3c0 .6-.4 1-1 1H7a2 2 0 0 1-2-2v-6.6l-.3.3a1 1 0 0 1-1.4-1.4l2-2 6-6Z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          Home
        </span>
      </div>
      <div className="mb-4 text-center" style={{ paddingLeft: '14px', paddingRight: '14px' }}>
        <div className="underline mb-2 mt-2 font-bold">Changelog</div>
      </div>
      <ul>
        {ChangelogData.map((entry, index) => (
          <div className="">
            <li key={index}>
              <strong>{entry.date}</strong>
              <ul>
                {entry.details.map((detail, idx) => (
                  <li key={idx}>{detail}</li>
                ))}
              </ul>
            </li>
            <hr className="border-grey-500 w-full mt-2 mb-2" />
          </div>
        ))}
      </ul>
    </div>
  );
};

export default Changelog;
