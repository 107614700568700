export interface Person {
  collection?: 'Medicinal' | 'Reserva' | 'Limited' | 'Illuminato' | 'Original' | 'Artisanal';
  id: string;
  link: string | null;
  name: string;
  parents: string[];
  featured?: boolean | null;
}

export const peopleData: Person[] = [
  {
    id: 'checkered-cookies',
    name: 'Checkered Cookies',
    parents: ['Marathon OG F3', 'Majesty Cookies F2'],
    link: 'https://www.frostfireseeds.com/home/strain-guide/checkered-cookies',
  },
  {
    id: 'checkered-cookies-f2',
    name: 'Checkered Cookies F2',
    parents: ['Checkered Cookies', 'Checkered Cookies'],
    link: 'https://www.frostfireseeds.com/home/strain-guide/checkered-cookies',
  },
  // ****
  {
    id: 'experimint-626',
    name: 'ExperiMint 626',
    parents: ['High Strangeness', 'Checkered Cookies F2'],
    link: 'https://www.frostfireseeds.com/home/strain-guide/experimint-626',
  },
  // ****
  {
    id: 'mango-isle-checkered-cookies',
    name: 'Mango Isle x Checkered Cookies F2',
    parents: ['Mango Isle', 'Checkered Cookies F2'],
    link: null,
  },
  // ****
  {
    id: 'tropick-punch',
    name: 'TropICK Punch',
    parents: ['Purple Spright', 'Checkered Cookies F2'],
    link: 'https://www.frostfireseeds.com/home/strain-guide/tropick-punch',
  },
  // ****
  {
    id: 'iced-haterade',
    name: 'Iced Haterade',
    parents: ['Sticky Situation', 'Checkered Cookies F2'],
    link: 'https://www.frostfireseeds.com/home/strain-guide/iced-haterade',
  },
  // ****
  {
    id: 'sour-froyo',
    name: 'Sour Froyo',
    parents: ['Sour Twist', 'Checkered Cookies F2'],
    link: 'https://www.frostfireseeds.com/home/strain-guide/sour-froyo',
  },
  // ****
  {
    id: 'majesty-cookies',
    name: 'Majesty Cookies',
    parents: ['Bubblegum Queen', 'Crinkled Cookies'],
    link: 'https://www.frostfireseeds.com/home/strain-guide/majesty-cookies',
  },
  // ****
  {
    id: 'candy-smash',
    name: 'Candy Smash',
    parents: ['Bruce Banner #3', 'Chem Candy'],
    link: 'https://www.frostfireseeds.com/home/strain-guide/candy-smash',
  },
  // ****
  {
    id: 'candied-zamango',
    name: 'Candied Zamango',
    parents: ['Zamango x Chem Candy', 'Candy Smash'],
    link: 'https://www.frostfireseeds.com/home/strain-guide/candied-zamango',
  },
  // ****
  {
    id: 'cherry-bangarang',
    name: 'Cherry Bangarang',
    parents: ['Cherry Cookie Smash', 'Purple Spright'],
    link: 'https://www.frostfireseeds.com/home/strain-guide/cherry-bangarang',
  },
  // ****
  {
    id: 'cherry-cookie-smash',
    name: 'Cherry Cookie Smash',
    parents: ['Candy Smash', 'Cherry Bang Bang #2 x Checkered Cookies F2'],
    link: 'https://www.frostfireseeds.com/home/strain-guide/cherry-cookie-smash',
  },
  // ****
  {
    id: 'cherry-bang-bang-2-checkered-cookies-f2',
    name: 'Cherry Bang Bang #2 x Checkered Cookies F2',
    parents: ['Cherry Bang Bang #2', 'Checkered Cookies F2'],
    link: null,
  },
  // ****
  {
    id: 'pineapple-fumes',
    name: 'Pineapple Fumes',
    parents: ['Pinapple Fields', 'S.O.D.K'],
    link: 'https://www.frostfireseeds.com/home/strain-guide/pineapple-fumes',
  },
  // ****
  {
    id: 'blue-biscuits',
    name: 'Blue Biscuits',
    parents: ['Blue Microverse F5', 'Checkered Cookies F2'],
    link: null,
  },
  // ****
  {
    id: 'cookie-scoops',
    name: 'Cookie Scoops',
    parents: ['Scoopski', 'Checkered Cookies'],
    link: null,
  },
  // ****
  {
    id: 'double-orange',
    name: 'Double Orange',
    parents: ['Space Station Orange V2', 'S.O.D.K'],
    link: null,
  },
  // ****
  // Cherry Bangarang 2024 Tester lineup 8-18-2024
  {
    id: 'storm-berry',
    name: 'StormBerry',
    parents: ['Juicy Scoops', 'Cherry Bangarang'],
    link: null,
  },
  {
    id: 'tangarang',
    name: 'Tangarang',
    parents: ['Double Orange', 'Cherry Bangarang'],
    link: null,
  },
  {
    id: 'nebula-punch',
    name: 'Nebula Punch',
    parents: ['Blue Biscuits', 'Cherry Bangarang'],
    link: null,
  },
  {
    id: 'blue-biscuits',
    name: 'Blue Biscuits',
    parents: ['Blue Microverse', 'Checkered Cookies'],
    link: null,
  },
  {
    id: '91-cherries',
    name: '91 Cherries',
    parents: ['91 Grapes', 'Cherry Bangarang'],
    link: null,
  },
];
