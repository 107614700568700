import { peopleData } from './AlebrijeData';
import BreederStrainWeb from './BreederStrainWeb';
import { type WebConfig } from './BreederStrainWeb';

const WEB_CONFIG: WebConfig = {
  breederName: 'Alebrije',
  parentStrainColor: '#ac1f8b',
  childStrainColor: '#f26e90',
  featuredColor: '#f5c014',
  nodeColorScaleRgb: 'rgb(82, 195, 202)',
  viewBoxHeight: 3450,
  viewBoxWidth: 3650,
  // Dropdown/url param/etc
  defaultStrainUrlParam: 'dawgwalker',
  defaultStrainName: 'Dawgwalker',
  defaultDropdownValue: {
    label: 'Dawgwalker',
    value: 'Dawgwalker',
    link: null,
  },
};

const Alebrije = () => (
  <div className="bg-[#a68a64] w-full h-screen overflow-auto font-brandon">
    <BreederStrainWeb people={peopleData} webConfig={WEB_CONFIG} />
  </div>
);

export default Alebrije;
