import { peopleData } from './RoninGardenData';
import BreederStrainWeb from './BreederStrainWeb';
import { type WebConfig } from './BreederStrainWeb';

const WEB_CONFIG: WebConfig = {
  breederName: 'Ronin Garden',
  parentStrainColor: '#f5c014',
  childStrainColor: '#61714D',
  featuredColor: '#f5c014',
  nodeColorScaleRgb: 'rgb(39, 145, 72)',
  viewBoxHeight: 4050,
  viewBoxWidth: 4200,
  // Dropdown/url param/etc
  defaultStrainUrlParam: 'high-strangeness',
  defaultStrainName: 'High Strangeness',
  defaultDropdownValue: {
    label: 'High Strangeness',
    value: 'High Strangeness',
    link: null,
  },
};

const RoninGarden = () => (
  <div className="bg-[#a68a64] w-full h-screen overflow-auto font-brandon">
    <BreederStrainWeb people={peopleData} webConfig={WEB_CONFIG} />
  </div>
);

export default RoninGarden;
