import { peopleData } from './NightOwlData';
import BreederStrainWeb from './BreederStrainWeb';
import { type WebConfig } from './BreederStrainWeb';

const WEB_CONFIG: WebConfig = {
  breederName: 'Night Owl',
  parentStrainColor: '#f5c014',
  childStrainColor: '#61714D',
  featuredColor: '#f5c014',
  nodeColorScaleRgb: 'rgb(39, 145, 72)',
  viewBoxHeight: 5250,
  viewBoxWidth: 5500,
  // Dropdown/url param/etc
  defaultStrainUrlParam: 'harvey-wallbanger',
  defaultStrainName: 'Harvey Wallbanger',
  defaultDropdownValue: {
    label: 'Harvey Wallbanger',
    value: 'Harvey Wallbanger',
    link: 'https://nightowlseeds.com/product/harvey-wallbanger-3/',
  },
};

const NightOwl = () => (
  <div className="bg-[#a68a64] w-full h-screen overflow-auto font-brandon">
    <BreederStrainWeb people={peopleData} webConfig={WEB_CONFIG} />
  </div>
);

export default NightOwl;
