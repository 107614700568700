export interface Person {
  collection?: 'Medicinal' | 'Reserva' | 'Limited' | 'Illuminato' | 'Original' | 'Artisanal';
  id: string;
  link: string | null;
  name: string;
  parents: string[];
  featured?: boolean | null;
}

export const peopleData: Person[] = [
  {
    id: '12-assed-raven',
    name: '12 Assed Raven',
    parents: ['4 Assed Monkey', 'Ravenberry'],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/12-assed-raven',
  },
  {
    id: '24-carat',
    name: '24 Carat',
    parents: ['Sour Boggle', 'Breeding Auto PI'],
    collection: 'Original',
    link: 'https://www.mephistogenetics.com/products/24-carat',
  },
  {
    id: '3-bears-og',
    name: '3 Bears OG',
    parents: ['Triangle', 'Bear OG'],
    collection: 'Artisanal',
    link: 'https://www.mephistogenetics.com/products/3-bears-og',
  },
  {
    id: '3wok-og',
    name: '3wok OG',
    parents: ['Skywalker', '3 Bears OG'],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/3wok-og',
  },
  {
    id: '4-assed-monkey',
    name: '4 Assed Monkey',
    parents: ['GG #4', 'Grape Crinkle'],
    collection: 'Artisanal',
    link: 'https://www.mephistogenetics.com/products/4-assed-monkey',
  },
  {
    id: 'alien-vs-triangle',
    name: 'Alien vs. Triangle',
    parents: ["Ripley's OG", 'Triangle'],
    collection: 'Reserva',
    link: 'https://www.mephistogenetics.com/products/alien-vs-triangle',
  },
  {
    id: 'aunt-ginnys-elixir',
    name: "Aunt Ginny's Elixir",
    parents: ['Jacky CBD', 'Walter White'],
    collection: 'Medicinal',
    link: 'https://www.mephistogenetics.com/products/aunt-ginnys-elixir',
  },
  {
    id: 'bear-assed-monkey',
    name: 'Bear Assed Monkey',
    parents: ['3 Bears OG', '4 Assed Monkey'],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/bear-assed-monkey',
  },
  {
    id: 'bear-chillz',
    name: 'Bear Chillz',
    parents: ['3 Bears OG', 'Sundae Thumper'],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/bear-chillz',
  },
  {
    id: 'beary-white',
    name: 'Beary White',
    parents: ['3 Bears OG', 'Walter White'],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/beary-white',
  },
  {
    id: 'berry-beast',
    name: 'Berry Beast',
    parents: ['Samsquanch OG', 'Ravenberry'],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/berry-beast',
  },
  {
    id: 'bigfoot-baked-alaska',
    name: 'Bigfood Baked Alaska',
    parents: ['Samsquanch OG', "Iced 'n Baked"],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/bigfoot-baked-alaska',
  },
  {
    id: 'blackberry-cookies',
    name: 'Blackberry Cookies',
    parents: ['Forgotten Cookies', 'Ravenberry'],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/blackberry-cookies',
  },
  {
    id: 'bubbasquanch',
    name: 'Bubbasquanch',
    parents: ['Samsquanch OG', 'Hubbabubbasmelloscope'],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/bubbasquanch',
  },
  {
    id: 'canna-cheese-1-1',
    name: 'CannaCheese',
    parents: ['Cannatonic', 'Northern Cheese Haze'],
    collection: 'Medicinal',
    link: 'https://www.mephistogenetics.com/products/canna-cheese-1-1',
  },
  {
    id: 'chemdogging',
    name: 'Chemdogging',
    parents: ['ChemD IBL', 'Breeding Auto OSS'],
    collection: 'Original',
    link: 'https://www.mephistogenetics.com/products/chemdogging',
  },
  {
    id: 'creamzicle',
    name: 'Creamzicle',
    parents: ['Super Orange Haze', 'Sundae Thumper'],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/creamzicle',
  },
  {
    id: 'creme-bubbly',
    name: 'Creme Bubbly',
    parents: ['Creme de la Chem', 'Hubbabubbasmelloscope'],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/creme-bubbly',
  },
  {
    id: 'creme-cheese',
    name: 'Creme Cheese',
    parents: ['Northern Cheese Haze', "Iced 'n Baked"],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/creme-cheese',
  },
  {
    id: 'creme-de-la-chem',
    name: 'Creme de la Chem',
    parents: ['Stardawg', 'Chemdogging'],
    collection: 'Artisanal',
    link: 'https://www.mephistogenetics.com/products/creme-de-la-chem',
  },
  {
    id: 'creme-tasmo',
    name: 'Creme-tasmo',
    parents: ['Creme de la Chem', 'Fantasmo Express'],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/creme-tasmo',
  },
  {
    id: 'deez-nuggs',
    name: 'Deez Nuggs',
    parents: ['Strawberry Nuggets', 'Orange Diesel'],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/deez-nuggs',
  },
  {
    id: 'double-grape',
    name: 'Double Grape',
    parents: ['Sour Stomper', 'Grape Crinkle'],
    collection: 'Reserva',
    link: 'https://www.mephistogenetics.com/products/double-grape',
  },
  {
    id: 'fairy-cake',
    name: 'Fairy Cake',
    parents: ['Toof Decay', "Iced 'n Baked"],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/fairy-cake',
  },
  {
    id: 'falcon-berry-og',
    name: 'Falcon-Berry OG',
    parents: ['Skywalker x Strawberry Nuggets', 'Ravenberry'],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/falcon-berry-og',
  },
  {
    id: 'fantasmo-express',
    name: 'Fantasmo Express',
    parents: ['OG Ghost Train Haze', 'Breeding Auto WW1'],
    collection: 'Original',
    link: 'https://www.mephistogenetics.com/products/fantasmo-express',
  },
  {
    id: 'flamingo-fusion',
    name: 'Flamingo Fusion',
    parents: ['Pink Panama', 'Ravenberry'],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/flamingo-fusion',
  },
  {
    id: 'forgotten-cookies',
    name: 'Forgotten Cookies',
    parents: ['Forum Stomper', 'Fugue State'],
    collection: 'Reserva',
    link: 'https://www.mephistogenetics.com/products/forgotten-cookies',
  },
  {
    id: 'forgotten-strawberries',
    name: 'Forgotten Strawberries',
    parents: ['Strawberry Nuggets', 'Fugue State'],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/forgotten-strawberries',
  },
  {
    id: 'forum-stomper',
    name: 'Forum Stomper',
    parents: ['Girl Scout Cookies Forum Cut', 'Sour Stomper'],
    collection: 'Artisanal',
    link: 'https://www.mephistogenetics.com/products/forum-stomper',
  },
  {
    id: 'frozen-sunny-d',
    name: 'Frozen Sunny-D',
    parents: ['Super Orange Haze', "Iced 'n Baked"],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/frozen-sunny-d',
  },
  {
    id: 'fugue-state',
    name: 'Fugue State',
    parents: ['Amnesia Haze', 'Walter White'],
    collection: 'Artisanal',
    link: 'https://www.mephistogenetics.com/products/fugue-state',
  },
  {
    id: 'galactic-gelato',
    name: 'Galactic Gelato',
    parents: ['Skystomper 2.0', "Iced 'n Baked"],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/galactic-gelato',
  },
  {
    id: 'gasoline-dream',
    name: 'Gasoline Dream',
    parents: ['Creme de la Chem', 'Sundae Thumper'],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/gasoline-dream',
  },
  {
    id: 'gelato-junction',
    name: 'Gelato Junction',
    parents: ['Fantasmo Express', 'Sundae Thumper'],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/gelato-junction',
  },
  {
    id: 'ghost-toof',
    name: 'Ghost Toof',
    parents: ['Toof Decay', 'Fantasmo Express'],
    collection: 'Reserva',
    link: 'https://www.mephistogenetics.com/products/ghost-toof',
  },
  {
    id: 'gold-glue',
    name: 'Gold Glue',
    parents: ['24 Carat', '4 Assed Monkey'],
    collection: 'Reserva',
    link: 'https://www.mephistogenetics.com/products/gold-glue',
  },
  {
    id: 'grape-crinkle',
    name: 'Grape Crinkle',
    parents: ["Ripley's OG", 'CCC x Supernatural'],
    collection: 'Artisanal',
    link: 'https://www.mephistogenetics.com/products/grape-crinkle',
  },
  {
    id: 'grape-walker-kush',
    name: 'Grape Walker Kush',
    parents: ['Grape Crinkle', 'Skywalker'],
    collection: 'Reserva',
    link: 'https://www.mephistogenetics.com/products/grape-walker-kush',
  },
  {
    id: 'grape-white-ape',
    name: 'Grape White Ape',
    parents: ['4 Assed Monkey', 'Walter White BX1'],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/grape-white-ape',
  },
  {
    id: 'grapey-walter-bx1',
    name: 'Grapey Walter BX1',
    parents: ['Walter White BX1', 'Grape Crinkle'],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/grapey-walter-bx1',
  },
  {
    id: 'grizzly-crinkle',
    name: 'Grizzly Crinkle',
    parents: ['Sour Crinkle', '3 Bears OG'],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/grizzly-crinkle',
  },
  {
    id: 'gus-scout-cookies',
    name: 'Gus Scout Cookies',
    parents: ['Walter White BX1', 'Forum Stomper'],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/gus-scout-cookies',
  },
  {
    id: 'heisenberg-special',
    name: 'Heisenberg Special',
    parents: ['Walter White', '24 Carat'],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/heisenberg-special',
  },
  {
    id: 'hitchcock-haze',
    name: 'Hitchcock Haze',
    parents: ['HubbaBubbaHaze', 'Ravenberry'],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/hitchcock-haze',
  },
  {
    id: 'hubbabubbahaze',
    name: 'HubbaBubbaHaze',
    parents: ['G13 Haze', 'Hubbabubbasmelloscope'],
    collection: 'Artisanal',
    link: 'https://www.mephistogenetics.com/products/hubbabubbahaze',
  },
  {
    id: 'hubbabubbasmelloscope',
    name: 'Hubbabubbasmelloscope',
    parents: ["90's Bubblegum", 'Breeding Auto WC1'],
    collection: 'Original',
    link: 'https://www.mephistogenetics.com/products/hubbabubbasmelloscope',
  },
  {
    id: 'i-scream-cake',
    name: 'I-Scream Cake',
    parents: ['Fantasmo Express', "Iced 'n Baked"],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/i-scream-cake',
  },
  {
    id: 'iced-n-baked',
    name: "Iced 'n Baked",
    parents: ["Mephisto's Wedding", 'Gelato #41'],
    collection: 'Artisanal',
    link: 'https://www.mephistogenetics.com/products/iced-n-baked',
  },
  {
    id: 'icenberg',
    name: 'Icenberg',
    parents: ['Sundae Thumper', 'Walter White BX1'],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/icenberg',
  },
  {
    id: 'jacobs-remedy',
    name: "Jacob's Remedy",
    parents: ['CannaCheese', 'Sour Crack'],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/jacobs-remedy',
  },
  {
    id: 'jammy-dodgers',
    name: 'Jammy Dodgers',
    parents: ['Forum Stomper', 'Strawberry Nuggets'],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/jammy-dodgers',
  },
  {
    id: 'jammy-dodgers-2',
    name: 'Jammy Dodgers #2',
    parents: ['Strawberry Nuggets', 'Forum Stomper'],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/jammy-dodgers-2',
  },
  {
    id: 'jedi-mind-juice',
    name: 'Jedi Mind Juice',
    parents: ['CannaCheese', 'Grape Walker Kush'],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/jedi-mind-juice',
  },
  {
    id: 'jolly-jay',
    name: 'Jolly Jay',
    parents: ['Grappa', 'Ravenberry'],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/jolly-jay',
  },
  {
    id: 'knickerbocker-glory',
    name: 'Knickerbocker Glory',
    parents: ['Jammy Dodgers', 'Sundae Thumper'],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/knickerbocker-glory',
  },
  {
    id: 'livers-bx1',
    name: 'Livers BX1',
    parents: ['Auto Livers', 'Livers'],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/livers-bx1',
  },
  {
    id: 'manbearaliencheese',
    name: 'ManBearAlienCheese',
    parents: ['CannaCheese', 'ManBearAlienPig'],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/manbearaliencheese',
  },
  {
    id: 'manbearalienpig',
    name: 'ManBearAlienPig',
    parents: ['3 Bears OG', "Ripley's OG"],
    collection: 'Reserva',
    link: 'https://www.mephistogenetics.com/products/manbearalienpig',
  },
  {
    id: 'mango-smile',
    name: 'Mango Smile',
    parents: ['Toof Decay', 'Mango Haze'],
    collection: 'Artisanal',
    link: 'https://www.mephistogenetics.com/products/mango-smile',
  },
  {
    id: 'mango-sorbet',
    name: 'Mango Sorbet',
    parents: ['Mango Smile', "Iced 'n Baked"],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/mango-sorbet',
  },
  {
    id: 'mephistos-wedding',
    name: "Mephisto's Wedding",
    parents: ['Creme de la Chem', 'Wedding Cake x Double Grape'],
    collection: 'Artisanal',
    link: 'https://www.mephistogenetics.com/products/mephistos-wedding',
  },
  {
    id: 'northern-cheese-haze',
    name: 'Northern Cheese Haze',
    parents: ['Fantasmo Express', 'Exodus Cheese x NL#5'],
    collection: 'Artisanal',
    link: 'https://www.mephistogenetics.com/products/northern-cheese-haze',
  },
  {
    id: 'octane-45',
    name: 'Octane 45',
    parents: ['Orange Diesel', 'Sundae Thumper'],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/octane-45',
  },
  {
    id: 'orange-biscuits',
    name: 'Orange Biscuits',
    parents: ['Forum Stomper', 'Orange Diesel'],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/orange-biscuits',
  },
  {
    id: 'orange-diesel',
    name: 'Orange Diesel',
    parents: ['Orange Diesel V3', 'S.O.D.K'],
    collection: 'Artisanal',
    link: 'https://www.mephistogenetics.com/products/orange-diesel',
  },
  {
    id: 'orange-utan',
    name: 'Orange-Utan',
    parents: ['4 Assed Monkey', 'Orange Diesel'],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/orange-utan',
  },
  {
    id: 'pearl-drops',
    name: 'Pearl Drops',
    parents: ['Walter White BX1', 'Toof Decay'],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/pearl-drops',
  },
  {
    id: 'pink-panama',
    name: 'Pink Panama',
    parents: ['Panama Deepchunk', 'Breeding Auto Pink Sativa'],
    collection: 'Artisanal',
    link: 'https://www.mephistogenetics.com/products/pink-panama',
  },
  {
    id: 'pinot-noir-2',
    name: 'Pinot Noir',
    parents: ['Double Grape', 'Creme de la Chem'],
    collection: 'Reserva',
    link: 'https://www.mephistogenetics.com/products/pinot-noir-2',
  },
  {
    id: 'polar-bear-og',
    name: 'Polar Bear OG',
    parents: ['3 Bears OG', "Iced 'n Baked"],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/polar-bear-og',
  },
  {
    id: 'purple-nuggets',
    name: 'Purple Nuggets',
    parents: ['Sour Strawberry Kush', '24 Carat'],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/purple-nuggets',
  },
  {
    id: 'quadruple-berry',
    name: 'Quadruple Berry',
    parents: ['Triple Grape', 'Ravenberry'],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/quadruple-berry',
  },
  {
    id: 'r2-abq',
    name: 'R2-ABQ',
    parents: ['Walter White BX1', 'Skywalker x Strawberry Nuggets'],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/r2-abq',
  },
  {
    id: 'ravenberry',
    name: 'Ravenberry',
    parents: ['Jammy Dodgers', 'Creme de la Chem'],
    collection: 'Reserva',
    link: 'https://www.mephistogenetics.com/products/ravenberry',
  },
  {
    id: 'ripleys-og',
    name: "Ripley's OG",
    parents: ['Alien OG', 'Breeding Auto OSS'],
    collection: 'Original',
    link: 'https://www.mephistogenetics.com/products/ripleys-og',
  },
  {
    id: 'ripleys-ripple',
    name: "Ripley's Ripple",
    parents: ["Ripley's OG", 'Sundae Thumper'],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/ripleys-ripple',
  },
  {
    id: 'rose-gold',
    name: 'Rose Gold',
    parents: ['Walter White BX1', 'Strawberry Nuggets'],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/rose-gold',
  },
  {
    id: 'salamanca-swirl',
    name: 'Salamanca Swirl',
    parents: ['Walter White', 'Sundae Thumper'],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/salamanca-swirl',
  },
  {
    id: 'sams-scoop',
    name: "Sam's Scoop",
    parents: ['Samsquanch OG', 'Sundae Thumper'],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/sams-scoop',
  },
  {
    id: 'screwball-haze',
    name: 'Screwball Haze',
    parents: ['HubbaBubbaHaze', 'Sundae Thumper'],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/screwball-haze',
  },
  {
    id: 'screwball-ice-creme',
    name: 'Screwball Ice Cream',
    parents: ['HubbaBubbaHaze', "Iced 'n Baked"],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/screwball-ice-creme',
  },
  {
    id: 'sod-breeding-auto-od1',
    name: 'S.O.D x Breeding Auto OD1',
    parents: ['S.O.D', 'Breeding Auto OD1'],
    link: null,
  },
  {
    id: 's-o-d-k',
    name: 'S.O.D.K',
    parents: ['Triangle', 'S.O.D x Breeding Auto OD1'],
    collection: 'Original',
    link: 'https://www.mephistogenetics.com/products/sodk',
  },
  {
    id: 'samsquanch-og',
    name: 'Samsquanch OG',
    parents: ['Yeti OG', '3 Bears OG'],
    collection: 'Artisanal',
    link: 'https://www.mephistogenetics.com/products/samsquanch-og',
  },
  {
    id: 'skylar-white',
    name: 'Skylar White',
    parents: ['Walter White', 'Skywalker'],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/skylar-white',
  },
  {
    id: 'skywalker',
    name: 'Skywalker',
    parents: ['Triangle', 'Skywalker Kush'],
    collection: 'Artisanal',
    link: 'https://www.mephistogenetics.com/products/skywalker',
  },
  {
    id: 'skystomper-2-0',
    name: 'SkyStomper 2.0',
    parents: ['Skywalker', 'Forum Stomper'],
    collection: 'Reserva',
    link: 'https://www.mephistogenetics.com/products/skystomper-2-0',
  },
  {
    id: 'skywalker-strawberry-nuggets',
    name: 'Skywalker x Strawberry Nuggets',
    parents: ['Skywalker', 'Strawberry Nuggets'],
    link: null,
  },
  {
    id: 'sour-bubbly',
    name: 'Sour Bubbly',
    parents: ['24 Carat', 'Hubbabubbasmelloscope'],
    collection: 'Original',
    link: 'https://www.mephistogenetics.com/products/sour-bubbly',
  },
  {
    id: 'sour-crack',
    name: 'Sour Crack',
    parents: ['Green Crack', '24 Carat'],
    collection: 'Original',
    link: 'https://www.mephistogenetics.com/products/sour-crack',
  },
  {
    id: 'sour-crinkle',
    name: 'Sour Crinkle',
    parents: ['Sour Crack', 'Grape Crinkle'],
    collection: 'Reserva',
    link: 'https://www.mephistogenetics.com/products/sour-crinkle',
  },
  {
    id: 'sour-livers',
    name: 'Sour Livers',
    parents: ['Livers', 'Sour Crack'],
    collection: 'Artisanal',
    link: 'https://www.mephistogenetics.com/products/sour-livers',
  },
  {
    id: 'sour-stomper',
    name: 'Sour Stomper',
    parents: ['GrapeStomper OG', 'Sour Crack'],
    collection: 'Artisanal',
    link: 'https://www.mephistogenetics.com/products/sour-stomper',
  },
  {
    id: 'stilton-special',
    name: 'Stilton Special',
    parents: ['Sour Livers', 'Northern Cheese Haze'],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/stilton-special',
  },
  {
    id: 'strawberry-nuggets',
    name: 'Strawberry Nuggets',
    parents: ['Sour Strawberry Kush', '24 Carat'],
    collection: 'Artisanal',
    link: 'https://www.mephistogenetics.com/products/strawberry-nuggets',
  },
  {
    id: 'strawberry-slushie',
    name: 'Strawberry Slushie',
    parents: ['Strawberry Nuggets', "Iced 'n Baked"],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/strawberry-slushie',
  },
  {
    id: 'sundae-best',
    name: 'Sundae Best',
    parents: ['Sundae Thumper', "Iced 'n Baked"],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/sundae-best',
  },
  {
    id: 'sundae-thumper',
    name: 'Sundae Thumper',
    parents: ['Gelato #45', 'Forum Stomper'],
    collection: 'Artisanal',
    link: 'https://www.mephistogenetics.com/products/sundae-thumper',
  },
  {
    id: 'super-orange-haze',
    name: 'Super Orange Haze',
    parents: ['Super Lemon Haze', 'S.O.D.K'],
    collection: 'Artisanal',
    link: 'https://www.mephistogenetics.com/products/super-orange-haze',
  },
  {
    id: 'sweet-n-sour',
    name: "Sweet 'n Sour",
    parents: ['Toof Decay', 'Sour Stomper'],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/sweet-n-sour',
  },
  {
    id: 'sweet-tweet',
    name: 'Sweet Tweet',
    parents: ["Iced 'n Baked", 'Ravenberry'],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/sweet-tweet',
  },
  {
    id: 'swirly-birdie',
    name: 'Swirly Birdie',
    parents: ['Sundae Thumper', 'Ravenberry'],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/swirly-birdie',
  },
  {
    id: 'toof-decay',
    name: 'Toof Decay',
    parents: ['Breeding Auto WC1', 'Grapefruit x Sweetooth'],
    collection: 'Original',
    link: 'https://www.mephistogenetics.com/products/toof-decay',
  },
  {
    id: 'toofless-alien',
    name: 'Toofless Alien',
    parents: ["Ripley's OG", 'Toof Decay'],
    collection: 'Reserva',
    link: 'https://www.mephistogenetics.com/products/toofless-alien',
  },
  {
    id: 'walter-white',
    name: 'Walter White',
    parents: ['Breeding Auto WC1', 'The White'],
    collection: 'Original',
    link: 'https://www.mephistogenetics.com/products/walter-white',
  },
  {
    id: 'walter-white-bx1',
    name: 'Walter White BX1',
    parents: ['Walter White', 'The White'],
    collection: 'Artisanal',
    link: 'https://www.mephistogenetics.com/products/walter-white-bx1',
  },
  {
    id: 'white-triangle',
    name: 'White Triangle',
    parents: ['Walter White', 'Triangle'],
    link: null,
  },
  {
    id: 'wedding-cake-double-grape',
    name: 'Wedding Cake x Double Grape',
    parents: ['Double Grape', 'Wedding Cake'],
    link: null,
  },
  {
    id: 'wedding-doves',
    name: 'Wedding Doves',
    parents: ["Mephisto's Wedding", 'Ravenberry'],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/wedding-doves',
  },
  {
    id: 'white-crack',
    name: 'White Crack',
    parents: ['Sour Crack', 'Walter White'],
    collection: 'Reserva',
    link: 'https://www.mephistogenetics.com/products/white-crack',
  },
  {
    id: 'white-raven',
    name: 'White Raven',
    parents: ['Walter White BX1', 'Ravenberry'],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/white-raven',
  },
  {
    id: 'white-wedding',
    name: 'White Wedding',
    parents: ['Walter White BX1', "Mephisto's Wedding"],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/white-wedding',
  },
  {
    id: 'zinfandel',
    name: 'Zinfandel',
    parents: ['Walter White BX1', 'Pinot Noir'],
    collection: 'Limited',
    link: 'https://www.mephistogenetics.com/products/zinfandel',
  },
  // ****** OSMH Drop
  {
    id: 'old-school-mango-haze',
    name: 'Old School Mango Haze',
    parents: ['Old School Haze', 'Mango Smile'],
    collection: 'Limited',
    featured: false,
    link: 'https://www.mephistogenetics.com/products/old-school-mango-haze',
  },
  {
    id: 'old-school-strawberry-haze',
    name: 'Old School Strawberry Haze',
    parents: ['Old School Mango Haze', 'Strawberry Nuggets'],
    collection: 'Limited',
    featured: false,
    link: 'https://www.mephistogenetics.com/products/old-school-strawberry-haze',
  },
  {
    id: 'throwback-thumper',
    name: 'Throwback Thumper',
    parents: ['Old School Mango Haze', 'Sundae Thumper'],
    collection: 'Limited',
    featured: false,
    link: 'https://www.mephistogenetics.com/products/throwback-thumper',
  },
  {
    id: 'retro-wedding',
    name: 'Retro Wedding',
    parents: ['Old School Mango Haze', "Mephisto's Wedding"],
    collection: 'Limited',
    featured: false,
    link: 'https://www.mephistogenetics.com/products/retro-wedding',
  },
  {
    id: 'willys-gum',
    name: "Willy's Gum",
    parents: ['Old School Mango Haze', 'HubbaBubbaHaze'],
    collection: 'Limited',
    featured: false,
    link: 'https://www.mephistogenetics.com/products/willys-gum',
  },
  {
    id: 'turbo-de-verano',
    name: 'Turbo de Verano',
    parents: ['Old School Mango Haze', 'Tinto de Verano'],
    collection: 'Limited',
    featured: false,
    link: 'https://www.mephistogenetics.com/products/turbo-de-verano',
  },
  {
    id: 'mango-sunset',
    name: 'Mango Sunset',
    parents: ['Old School Mango Haze', 'Super Orange Haze'],
    collection: 'Limited',
    featured: false,
    link: 'https://www.mephistogenetics.com/products/mango-sunset',
  },
  {
    id: 'iced-n-hazed',
    name: "Iced 'n Hazed",
    parents: ['Old School Mango Haze', "Iced 'n Baked"],
    collection: 'Limited',
    featured: false,
    link: 'https://www.mephistogenetics.com/products/iced-n-hazed',
  },
  {
    id: 'ol-dirty-bearstard',
    name: "Ol' Dirty Beastard",
    parents: ['Old School Mango Haze', '3 Bears OG'],
    collection: 'Limited',
    featured: false,
    link: 'https://www.mephistogenetics.com/products/ol-dirty-bearstard',
  },
  {
    id: 'rasinettes',
    name: 'Rasinettes',
    parents: ['Old School Mango Haze', 'Grape Crinkle'],
    collection: 'Limited',
    featured: false,
    link: 'https://www.mephistogenetics.com/products/rasinettes',
  },
  // ****** CBD Blue Auto Drop
  {
    id: 'thumper-therapy',
    name: 'Thumper Therapy',
    parents: ['Blue Auto CBD', 'Sundae Thumper'],
    collection: 'Limited',
    featured: false,
    link: 'https://www.mephistogenetics.com/products/thumper-therapy',
  },
  {
    id: 'blue-c-birdie',
    name: 'Blue C-Birdie',
    parents: ['Blue Auto CBD', 'Ravenberry'],
    collection: 'Limited',
    featured: false,
    link: 'https://www.mephistogenetics.com/products/blue-c-birdie',
  },
  {
    id: 'blue-auto-cbd',
    name: 'Blue Auto CBD',
    parents: ['Blue Genius', 'Blue Genius'],
    collection: 'Limited',
    featured: false,
    link: null,
  },
  {
    id: '1-walt',
    name: '1:Walt',
    parents: ['CannaCheese', 'Walter White BX1'],
    collection: 'Limited',
    featured: false,
    link: 'https://www.mephistogenetics.com/products/1-walt',
  },
  {
    id: 'medi-mango',
    name: 'Medi-Mango',
    parents: ['CannaCheese CBD', 'Old School Mango Haze'],
    collection: 'Limited',
    featured: false,
    link: 'https://www.mephistogenetics.com/products/medi-mango',
  },
  {
    id: 'canna-cheese-cbd',
    name: 'CannaCheese CBD',
    parents: ['CannaCheese', 'CannaCheese'],
    collection: 'Limited',
    featured: false,
    link: null,
  },
  // **** Mephistoreoz
  {
    id: 'mephistoreoz',
    name: 'MephistOreoz',
    parents: ['Sour Stomper x Double Grape', 'Oreoz'],
    collection: 'Limited',
    featured: false,
    link: 'https://www.mephistogenetics.com/products/mephistoreoz',
  },
  {
    id: 'sour-stomper-double-grape',
    name: 'Sour Stomper x Double Grape',
    parents: ['Stour Stomper', 'Double Grape'],
    collection: 'Limited',
    featured: false,
    link: null,
  },
  // **** Crosses
  {
    id: 'mangoreoz',
    name: 'MangOreoz',
    parents: ['MephistOreoz', 'Mango Smile'],
    collection: 'Limited',
    featured: false,
    link: 'https://mephistogenetics.com/products/mangoreoz',
  },
  // More
  {
    id: 'golden-oreoz',
    name: 'Golden Oreoz',
    parents: ['MephistOreoz', '24 Carat'],
    collection: 'Limited',
    featured: false,
    link: 'https://mephistogenetics.com/products/golden-oreoz',
  },
  {
    id: 'creme-oreoz',
    name: 'Creme Oreoz',
    parents: ['MephistOreoz', 'White Creme'],
    collection: 'Limited',
    featured: false,
    link: 'https://mephistogenetics.com/products/creme-oreoz',
  },
  {
    id: 'blueberry-pie-oreoz',
    name: 'Blueberry Pie Oreoz',
    parents: ['MephistOreoz', 'Toof Decay'],
    collection: 'Limited',
    featured: false,
    link: 'https://mephistogenetics.com/products/blueberry-pie-oreoz',
  },
  {
    id: 'birthday-cake-oreoz',
    name: 'Birthday Cake Oreoz',
    parents: ['MephistOreoz', "Mephisto's Wedding"],
    collection: 'Limited',
    featured: false,
    link: 'https://mephistogenetics.com/products/birthday-cake-oreoz',
  },
  {
    id: 'limeade-oreoz',
    name: 'Limeade Oreoz',
    parents: ['MephistOreoz', 'El Chemi Kiwi'],
    collection: 'Limited',
    featured: false,
    link: 'https://mephistogenetics.com/products/limeade-oreoz',
  },
  {
    id: 'orange-oreoz',
    name: 'Orange Oreoz',
    parents: ['MephistOreoz', 'Super Orange Haze'],
    collection: 'Limited',
    featured: false,
    link: 'https://mephistogenetics.com/products/orange-oreoz',
  },
  {
    id: 'neapolitan-oreoz',
    name: 'Neapolitan Oreoz',
    parents: ['MephistOreoz', 'Strawberry Nuggets'],
    collection: 'Limited',
    featured: false,
    link: 'https://mephistogenetics.com/products/neapolitan-oreoz',
  },
  {
    id: 'jelly-donut-oreoz',
    name: 'Jelly Donut Oreoz',
    parents: ['MephistOreoz', 'Jammy Dodgers'],
    collection: 'Limited',
    featured: false,
    link: 'https://mephistogenetics.com/products/jelly-donut-oreoz',
  },
  {
    id: 'lemon-oreoz',
    name: 'Lemon Oreoz',
    parents: ['MephistOreoz', 'Skylar White'],
    collection: 'Limited',
    featured: false,
    link: 'https://mephistogenetics.com/products/lemon-oreoz',
  },
  {
    id: 'beary-oreoz',
    name: 'Beary Oreoz',
    parents: ['MephistOreoz', '3 Bears OG'],
    collection: 'Limited',
    featured: false,
    link: 'https://mephistogenetics.com/products/beary-oreoz',
  },
];
