import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { track } from '@vercel/analytics';

import '../../App.css';

const formattedInputNumber = (inputValue: string) => {
  // The setState for a numerical value will accept a string if you send this as any
  return inputValue.replace(/[^0-9.]/g, '') as any;
};

const DliCalculator = () => {
  // State for PPFD, hours, and DLI
  const [ppfd, setPpfd] = useState<number>(0);
  const [hours, setHours] = useState<number>(0);
  const [dli, setDli] = useState<number | null>(null);

  const navigate = useNavigate();

  // Handle calculation
  const calculateDLI = (e: React.MouseEvent<HTMLButtonElement>) => {
    // Prevent the default button action (page refresh)
    e.preventDefault();

    track(`Button Clicked: DLI Calculator - Calculate`);

    // Basic validation
    if (isNaN(ppfd) || isNaN(hours) || ppfd < 0 || hours < 0) {
      alert('Please enter valid numbers for PPFD and hours.');
      return;
    }

    // DLI calculation
    const result = (ppfd * hours * 3600) / 1000000;
    setDli(result);
  };

  return (
    <div className="bg-[#f6ffff] font-brandon flex flex-col items-center pb-10" style={{ minHeight: '100vh' }}>
      <div className="mb-2 mt-4 text-md">
        <span
          className="underline cursor-pointer flex flex-row justify-between font-bold"
          onClick={() => {
            navigate('/');
            track(`Button Clicked: Home - DLI Calculator`);
          }}
        >
          <div className="flex items-center">
            <svg
              className="w-4 h-4 text-gray-800 dark:text-black"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                fillRule="evenodd"
                d="M11.3 3.3a1 1 0 0 1 1.4 0l6 6 2 2a1 1 0 0 1-1.4 1.4l-.3-.3V19a2 2 0 0 1-2 2h-3a1 1 0 0 1-1-1v-3h-2v3c0 .6-.4 1-1 1H7a2 2 0 0 1-2-2v-6.6l-.3.3a1 1 0 0 1-1.4-1.4l2-2 6-6Z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          Home
        </span>
      </div>
      <div className="mb-4 text-center" style={{ paddingLeft: '14px', paddingRight: '14px' }}>
        <div className="underline mb-2 mt-2 font-bold">What is Daily Light Integral (DLI)?</div>
        <div className="mb-6">
          DLI is a crucial measure in horticulture, determining the total amount of usable light (photosynthetically
          active radiation, or PAR) plants receive in a day. It's calculated using a Photosynthetic Photon Flux Density
          (PPFD) measurement and the number of hours of light per day.
        </div>
        <div className="mb-6">
          Making sure your DLI is dialed in will ensure healthy growth and maximum yields while also ensuring you aren't
          wasting electricity.
        </div>
        <hr className="border-grey-500 w-full mt-2" />
      </div>
      <form className="mx-4 my-4 px-4 py-4 border border-gray-300 rounded-lg bg-[#ffffff]">
        <div className="mb-4 mt-2">
          <h2 className="underline font-bold">Daily Light Integral (DLI) Calculator</h2>
        </div>
        <div className="items-center text-center justify-center">
          <div>
            <label className="mb-2 text-xs font-medium text-gray-900">PPFD (µmol/m²/s) </label>
            <input
              type="number"
              pattern="[0-9]*"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm text-center rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              value={ppfd}
              onChange={e => setPpfd(formattedInputNumber(e.target.value))}
            />
          </div>
          <div className="mt-3">
            <label className="mb-2 text-xs font-medium text-gray-900">Hours of light per day </label>
            <input
              type="number"
              pattern="[0-9]*"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm text-center rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
              value={hours}
              onChange={e => setHours(formattedInputNumber(e.target.value))}
            />
          </div>
          <div className="mt-5 mb-5">
            <button
              type="submit"
              className="text-black bg-gradient-to-br from-pink-500 to-orange-400 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-pink-200 dark:focus:ring-pink-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              onClick={e => calculateDLI(e)}
            >
              Calculate
            </button>
          </div>
          <div>
            {!!dli && (
              <p>
                <span className="font-bold">Calculated DLI:</span> {dli.toFixed(2)} mol/m²/day
              </p>
            )}
          </div>
        </div>
      </form>
      <div className="mb-4 text-center" style={{ paddingLeft: '14px', paddingRight: '14px' }}>
        <hr className="border-grey-500 w-full mt-4" />
        <div className="underline mb-2 mt-5 font-bold">Target DLI Readings</div>
        <div className="flex justify-center">
          <img
            src="/autoflower-dli-chart.png"
            alt="logo"
            style={{ width: '100%', height: 'auto', maxWidth: '690px' }}
            className="rounded-lg border border-gray-300"
          />
        </div>
      </div>
    </div>
  );
};

export default DliCalculator;
