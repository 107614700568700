export const calculateBubbleRadius = (connections: number): number => {
  const minInput: number = 1;
  const maxInput: number = 20;
  const minOutput: number = 45;
  const maxOutput: number = 140;

  // Linear interpolation to map the connections to the desired output range
  let newValue: number = minOutput + ((connections - minInput) / (maxInput - minInput)) * (maxOutput - minOutput);

  // Ensure the output is within the desired range
  newValue = Math.max(Math.min(newValue, maxOutput), minOutput);

  return newValue;
};
