import { peopleData } from './FrostFireData';
import BreederStrainWeb from './BreederStrainWeb';
import { type WebConfig } from './BreederStrainWeb';

const WEB_CONFIG: WebConfig = {
  breederName: 'FrostFire',
  parentStrainColor: '#003458',
  childStrainColor: '#ff8749',
  featuredColor: '#f5c014',
  nodeColorScaleRgb: 'rgb(47, 249, 250)',
  viewBoxHeight: 4150,
  viewBoxWidth: 3950,
  // Dropdown/url param/etc
  defaultStrainUrlParam: 'checkered-cookies-f2',
  defaultStrainName: 'Checkered Cookies F2',
  defaultDropdownValue: {
    label: 'Checkered Cookies F2',
    value: 'Checkered Cookies F2',
    link: 'https://www.frostfireseeds.com/home/strain-guide/checkered-cookies',
  },
};

const FrostFire = () => (
  <div className="bg-[#3b86a6] w-full h-screen overflow-auto font-brandon">
    <BreederStrainWeb people={peopleData} webConfig={WEB_CONFIG} />
  </div>
);

export default FrostFire;
