export interface Person {
  collection?: 'Medicinal' | 'Reserva' | 'Limited' | 'Illuminato' | 'Original' | 'Artisanal';
  id: string;
  link: string | null;
  name: string;
  parents: string[];
  featured?: boolean | null;
}

export const peopleData: Person[] = [
  // *** Breeding Stock
  {
    id: 'frogger',
    name: 'Frogger',
    parents: ['Frosted Guava', 'Chemdogging'],
    link: null,
  },
  // ****
  {
    id: 'double-grape-slurricane',
    name: 'Double Grape Slurricane',
    parents: ['Grape Slurri', 'Double Grape'],
    link: null,
  },
  // ****
  {
    id: 'dgkb',
    name: 'D.G.K.B',
    parents: ['Kong Breath', 'Double Grape'],
    link: null,
  },
  // ****
  // ****
  // ****
  // ****
  // ****
  // Cultivars
  // ****
  // ****
  // ****
  // ****
  // ****
  {
    id: 'kerosene-kermit',
    name: 'Kerosene Kermit  ',
    parents: ['Kerosene Krash', 'Frogger'],
    link: null,
  },
  // ****
  {
    id: 'juicy-fruit',
    name: 'Juicy Fruit',
    parents: ['Hubbabubbasmelloscope', 'Double Grape Slurricane'],
    link: null,
  },
  // ****
  {
    id: 'beskar',
    name: 'Beskar',
    parents: ['Lilac Diesel', 'Frogger'],
    link: null,
  },
  // ****
  {
    id: 'betty-white-xmas',
    name: 'Betty White Xmas',
    parents: ['White Xmas', 'Double Grape Slurricane'],
    link: null,
  },
  // ****
  {
    id: 'mighty-quinn',
    name: 'Mighty Quinn',
    parents: ['Bruce Banner #3', 'D.G.K.B'],
    link: null,
  },
  // ****
  {
    id: 'frozen-kong',
    name: 'Frozen Kong',
    parents: ['D.G.K.B', 'Frogger'],
    link: null,
  },
  // ****
  {
    id: 'citric-acid',
    name: 'Citric Acid',
    parents: ['Pina', 'Double Grape Slurricane'],
    link: null,
  },
  // ****
  {
    id: 'queens-mace',
    name: 'Queens Mace',
    parents: ['Queens Banner', 'Double Grape Slurricane'],
    link: null,
  },
  // ****
  {
    id: 'gold-medal',
    name: 'Gold Medal',
    parents: ['Marathon OG', 'D.G.K.B'],
    link: null,
  },
  // ****
  {
    id: 'polywog',
    name: 'Polywog',
    parents: ['Pina', 'Frogger'],
    link: null,
  },
  // ****
];
